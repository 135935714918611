import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Icons } from '../../cons/icons';
import { ListarAmbienteMesaService } from '../listar-ambiente-mesa/listar-ambiente-mesa.service';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from '../../helpers/decorators/AutoUnsubscribe';
import { Pedido } from './models/Pedido';
import { Utils } from '../../helpers/utils';
import { UIService } from '../../services/ui.services';
import { CriteriosBusqueda, Length_Database, ListaTipoProducto, Msjs, Msjs_Validations, PatternsRegex, SistOperation, TableTypes } from '../../cons/common';
import { SnackBarType } from '../../types/snackbar-type';
import { RegistroService } from '../../services/registro.service';
import { IMesaAmbiente } from './models/IMesaAmbiente';
import { IAmbienteMesa } from '../procesos/gestion-ambientes-mesas/models/IAmbienteMesa';
import { EstadosMesaType } from '../../types/estado-mesa-type';
import { GestionAmbientesBlService } from '../procesos/gestion-ambientes-mesas/services/services.bl/gestion-ambientes.bl.service';
import { TableService } from '../table/table.service';
import { ITableEvento } from '../table/ITableEvento';
import { Catalogo } from '../procesos/dialog/models/Catalogo';
import { Producto } from '../procesos/dialog/models/Producto';
import { IProductoTicket } from './models/IProductoTicket';
import { GestionAspectosBlService } from '../procesos/gestion-aspectos-generales/services/services.bl/gestion-aspectos.bl.service';
import { AspectosGenerales } from '../procesos/gestion-aspectos-generales/models/AspectosGenerales';
import { NgForm } from '@angular/forms';
import { PedidoBlService } from './services/services.bl/pedido.bl.service';
import { MediaObserver } from '@angular/flex-layout';
import { FunctionsService } from '../../services/functions.service';
import * as moment from "moment";
import { IProducto } from '../procesos/dialog/models/IProducto';
import { ICatalogo } from '../procesos/dialog/models/ICatalogo';
import { GestionMontoBlService } from '../gestion-monto/services/gestion-monto.bl.service';
import { filter } from 'rxjs/operators';
import { SpinnerPageService } from '../spinner-page/spinner-page.service';
import { EstadosPedidoType } from '../../types/estado-pedido-type';
import { EstadoPedido } from './models/EstadoPedido';
import { SolicitarCambioType } from '../../types/solicitar-cambio-type';
import { ProcesoType } from '../../types/proceso-type';
import { blinkAnimation } from '../../animations/animations';
import { EstadosProductoType } from '../../types/estado-producto-type';
import { EstadosProducto } from './models/EstadoProducto';
import { Fecha } from '../gestion-monto/models/Fecha';
import { AccionMesaType } from '../../types/accion-mesa-type';
import { MatAccordion } from '@angular/material/expansion';
import { ISendPedidoRegistro } from '../../services/models/ISendPedidoRegistro';
import { MatTabGroup } from '@angular/material/tabs';
import { ICaracteristicaProducto } from './models/CaracteristicaProducto';
import { TipoOperacionProductoCompuesto } from '../../types/tipo-operacion-prod-comp-type';
import { UINavigateSidenavService } from '../../services/ui.navigate-sidenav.services';
import { GestionColaboradorBlService } from '../procesos/gestion-colaborador/services/services.bl/gestion-colaborador.bl.service';
import { IRolAcceso } from '../../models/item.model';
import { CColaborador } from '../procesos/gestion-colaborador/models/ccolaborador.model';
import { IUsuarioIncidencia } from './models/IUsuarioIncidencia';

@Component({
  selector: 'app-registrar-pedido',
  templateUrl: './registrar-pedido.component.html',
  styleUrls: ['./registrar-pedido.component.scss'],
  animations: [blinkAnimation],
})
@AutoUnsubscribe
export class RegistrarPedidoComponent implements OnInit {
  @ViewChild(MatAccordion) accordion!: MatAccordion;
  blinkState: string = 'inactive';
  readonly Icons: typeof Icons = Icons;
  selecMesaSb = new Subscription();
  isDisabledMesa: boolean = false;
  showCatalogo: boolean = false;
  pedido = new Pedido();
  listaProductoPedido: IProductoTicket[] = [];
  listaProductoEliminado: IProductoTicket[] = [];
  listaProductoGuardadoPrevio: IProductoTicket[] = [];
  codigoTicket: string = '';
  btnVerSb = new Subscription();
  rowSelectSb = new Subscription();
  btnAgregarSb = new Subscription();
  btnAgregarSbBtn = new Subscription();
  listaProductoSb!: Subscription;
  nombreCatalogoSel: string = '';
  nombreProductoComboSel: string = '';
  tipoTabla:string = TableTypes.REGISTRAR_PEDIDO;

  readonly PatternsRegex: typeof PatternsRegex = PatternsRegex;
  readonly Msjs_Validations: typeof Msjs_Validations = Msjs_Validations;
  readonly Length_Database: typeof Length_Database = Length_Database;
  readonly CriteriosBusqueda: typeof CriteriosBusqueda = CriteriosBusqueda;
  readonly EstadosMesaType: typeof EstadosMesaType = EstadosMesaType;
  readonly SistOperation: typeof SistOperation = SistOperation;
  readonly SolicitarCambioType: typeof SolicitarCambioType = SolicitarCambioType;
  readonly AccionMesaType: typeof AccionMesaType = AccionMesaType;
  readonly EstadosProductoType: typeof EstadosProductoType = EstadosProductoType;
  readonly EstadosPedidoType: typeof EstadosPedidoType = EstadosPedidoType;
  readonly ListaTipoProducto: typeof ListaTipoProducto = ListaTipoProducto;  

  tasasSb = new Subscription();
  aspectosGenerales = new AspectosGenerales();
  denseList: boolean = false;
  mediaSub!: Subscription;
  tamanioPantalla: string = '';
  esPantallaXs: boolean = false;
  fechaHoy: Fecha = new Fecha();
  visibleNuevoPedido: boolean = true;
  //solo busqueda
  catalogoSeleccionado = new Catalogo();
  modalSb!: Subscription;
  turnoSb!: Subscription;
  showRegisterSb!: Subscription;
  showAgregarSb!: Subscription;
  esEditar: boolean = false;
  esAgregar: boolean = false;
  esEditarMesa: boolean = false;
  esEditarProducto: boolean = false;
  esNuevo: boolean = false;
  operacion: SistOperation = SistOperation.VER; // se inicializa con ver como fake
  tieneTurnoAperturado: boolean = false;
  idTurno: string = '';
  isLoading: boolean = false;
  isLoadingCatalogo:boolean = false;
  esAdministrador: boolean = false;
  esVigenteSolicitudMesa: boolean = false;
  esVigenteSolicitudProducto: boolean = false;
  varMesaSolicitado: IAmbienteMesa[] = new Array();
  historialVariacionMesa: IAmbienteMesa[] = new Array();
  historialVariacionProducto: EstadosProducto[] = new Array();
  historialVariacionPedido: EstadoPedido = new EstadoPedido();
  esPedidoIncidencia:boolean=false;
  loadingPedidoIncidencia:boolean = false;

  @ViewChild('matTabGroupPedido') matTabGroupPed!: MatTabGroup;

  incidenciaColaborador:CColaborador = new CColaborador();
  listaColaboradoresInc:CColaborador[] = [];

  constructor(
    private listarAmbienteMesaService: ListarAmbienteMesaService,
    private uiService: UIService,
    private registroService: RegistroService,
    private gestionAmbienteBLService: GestionAmbientesBlService,
    private tableService: TableService,
    private el: ElementRef,
    private gestionAspectosBL: GestionAspectosBlService,
    private pedidoBLService: PedidoBlService,
    private mediaObserver: MediaObserver,
    private functionService: FunctionsService,
    private gestionMontoBLService: GestionMontoBlService,
    private readonly spinnerPageService: SpinnerPageService,
    private changeDetectorRef: ChangeDetectorRef,
    private uiNavigateSide: UINavigateSidenavService,
    private gestionColaboradorBLService: GestionColaboradorBlService
  ) {}

  ngOnInit(): void {
    setInterval(() => {
      this.blinkState = this.blinkState === 'active' ? 'inactive' : 'active';
    }, 1000);

    if (this.registroService.empresa) {
      this.fechaHoy = new Fecha();
      this.cargarTasas();

      if (this.registroService.esAdministrador) {
        this.esAdministrador = true;
      }

      //verificando existencia de turno aperturado
      this.isLoading = true;
      this.gestionMontoBLService.existFetchTurno();
      this.turnoSb = this.gestionMontoBLService
        .getTurno()
        .pipe(filter((turno) => turno !== undefined))
        .subscribe((turno) => {
          if (turno && turno.id) {
            this.idTurno = turno.id;
            this.isLoading = false;
            this.tieneTurnoAperturado = true;
          } else {
            this.isLoading = false;
            this.tieneTurnoAperturado = false;
            this.spinnerPageService.hide();
          }
        });
    }

    // Suscribirse a los cambios en el tamaño de pantalla
    this.mediaSub = this.mediaObserver.asObservable().subscribe((changes) => {
      this.tamanioPantalla = changes[0].mqAlias;
      this.redimensionaPantalla();
    });

    this.selecMesaSb = this.listarAmbienteMesaService.seleccionMesa$.subscribe(async (mesaAmbMesaSel) => {
        if (mesaAmbMesaSel.tipoTabla == this.tipoTabla) {
          //mostrar loading del catalogo evite el lag del cargar catalogo
          if (this.pedido.ambiente_mesa.length == 0 && !this.showCatalogo) {
            this.isLoadingCatalogo = true;
          } else {
            this.isLoadingCatalogo = false;
          }

          //creando nuevo objeto
          let mesaAmbMesa: IAmbienteMesa = JSON.parse(
            JSON.stringify(mesaAmbMesaSel.mesaAmbMesa)
          );
          //cuando es editar
          if (this.esEditar) {
            if (this.fechaHoy.anio == '') {
              await this.cargarFechas();
            }

            //deseleccionado la mesa
            if (mesaAmbMesa.mesa_estado == EstadosMesaType.DISPONIBLE) {
              //filtrando la mesa
              const mesaExiste = this.pedido.ambiente_mesa.filter(
                (ambMesa) =>
                  (ambMesa.ambiente != mesaAmbMesa.ambiente &&
                    ambMesa.mesa_numero == mesaAmbMesa.mesa_numero) ||
                  (ambMesa.ambiente == mesaAmbMesa.ambiente &&
                    ambMesa.mesa_numero !== mesaAmbMesa.mesa_numero)
              );
              this.pedido.ambiente_mesa = mesaExiste;

              if (this.pedido.ambiente_mesa.length == 0) {
                this.pedido = new Pedido();
                this.visibleNuevoPedido = true;
              }
              // muestra el loading en el lugar del catalogo debido a la demora asincrona
              this.isLoadingCatalogo = false;
              return;
            }

            //cuando se agrega la nueva mesa
            mesaAmbMesa.fecha_key = {
              anio: this.fechaHoy.anio,
              mes: this.fechaHoy.mes,
              dia: this.fechaHoy.dia,
              hora: this.fechaHoy.hora,
              minuto: this.fechaHoy.minuto,
              segundo: this.fechaHoy.segundo,
            };
            mesaAmbMesa.usuario_creacion =
              this.registroService.usuario.correo_electronico;
            mesaAmbMesa.es_vigente = true;
            mesaAmbMesa.accion_mesa = AccionMesaType.ANIADIR; //cuando añade una mesa
            mesaAmbMesa.estado_cambio = SolicitarCambioType.SOLICITADO;
            mesaAmbMesa.proceso = ProcesoType.CAMBIAR_VARIAR_MESA;
            mesaAmbMesa.auto_numerico = Utils.generaMesHoraAutonumerico(
              this.fechaHoy
            );
            this.pedido.ambiente_mesa.push(mesaAmbMesa);
            //si es administrador se auto aprueba previamente antes de guardar
            return;
          }
          //nuevo pedido
          else {
            if (
              this.pedido.ambiente_mesa.length == 0 &&
              mesaAmbMesa.mesa_estado == EstadosMesaType.EN_PROCESO
            ) {
              this.inicializarVariables();
              await this.cargarFechas();
              this.pedido.fecha_key = this.fechaHoy;
            }
            //deseleccionado la mesa
            if (mesaAmbMesa.mesa_estado == EstadosMesaType.DISPONIBLE) {
              //filtrando la mesa
              const mesaExiste = this.pedido.ambiente_mesa.filter(
                (ambMesa) =>
                  (ambMesa.ambiente != mesaAmbMesa.ambiente &&
                    ambMesa.mesa_numero == mesaAmbMesa.mesa_numero) ||
                  (ambMesa.ambiente == mesaAmbMesa.ambiente &&
                    ambMesa.mesa_numero !== mesaAmbMesa.mesa_numero)
              );
              this.pedido.ambiente_mesa = mesaExiste;

              if (this.pedido.ambiente_mesa.length == 0) {
                this.pedido = new Pedido();
                this.showCatalogo = false;
                this.esNuevo = false;
                this.visibleNuevoPedido = true;
              }
              // muestra el loading en el lugar del catalogo debido a la demora asincrona
              this.isLoadingCatalogo = false;
              return;
            }

            //curso normal de agregar la mesa
            this.showCatalogo = true;
            this.esNuevo = true;

            if (this.pedido.codigo_pedido == '') {
              this.pedido.codigo_pedido = Utils.generarCodigoRandom(6);
            }
            if (this.registroService.usuario) {
              this.pedido.usuario_creacion =
                this.registroService.usuario.correo_electronico;
            }

            mesaAmbMesa.fecha_key = this.fechaHoy;
            mesaAmbMesa.auto_numerico = Utils.generaMesHoraAutonumerico(
              this.fechaHoy
            );
            mesaAmbMesa.usuario_creacion =
              this.registroService.usuario.correo_electronico;
            mesaAmbMesa.es_vigente = true;
            mesaAmbMesa.accion_mesa = AccionMesaType.ANIADIR; //cuando añade una mesa

            this.pedido.ambiente_mesa.push(mesaAmbMesa);
            this.visibleNuevoPedido = false;
            //marcamos como nuevo a la operacion
            this.operacion = SistOperation.NUEVO;
            // muestra el loading en el lugar del catalogo debido a la demora asincrona
            this.isLoadingCatalogo = false;
          }
        }
      }
    );

    //seleccionar catalogo
    this.btnVerSb = this.tableService.btnVer$.subscribe(
      (callEvento: ITableEvento) => {
        if (TableTypes.LISTAR_CATALOGO == callEvento.key) {
          this.nombreCatalogoSel = (
            callEvento.data as Catalogo
          ).nombre_catalogo;
          this.catalogoSeleccionado = callEvento.data as Catalogo; //para busqueda into
        }
      }
    );

    //agrega productos a la lista pedido
    this.btnAgregarSbBtn = this.tableService.btnAgregar$.subscribe(
      (callEvento: ITableEvento) => {
        if (TableTypes.LISTAR_PRODUCTO == callEvento.key) {
          //agrega  producto a la lista de pedido
          const prodSel: Producto = JSON.parse(
            JSON.stringify(callEvento.data as Producto)
          );
          if (prodSel.stock_ilimitado) {
            this.procesarAgregarProducto(prodSel);
          } else {
            if (prodSel.cantidad_stock && prodSel.cantidad_stock >= 1) {
              this.procesarAgregarProducto(prodSel);
            } else {
              this.uiService.ShowSnackBar(
                SnackBarType.ERROR,
                Msjs.MSJ_PRODUCTO_CANTIDAD_MINIMA_NECESARIA,
                3000
              );
              return;
            }
          }
        }
      }
    );

    //sirve para catalogo y para producto,
    this.rowSelectSb = this.tableService.rowSelect$.subscribe(
      (callEvento: ITableEvento) => {
        //para catalogo
        if (TableTypes.LISTAR_CATALOGO == callEvento.key) {
          this.matTabGroupPed.selectedIndex = 1;
          this.changeDetectorRef.detectChanges();
          this.nombreCatalogoSel = (
            callEvento.data as Catalogo
          ).nombre_catalogo;
          this.catalogoSeleccionado = JSON.parse(
            JSON.stringify(callEvento.data as Catalogo)
          ); //para busqueda into
          //posicionar la pantalla en ese elemento
          const element = this.el.nativeElement.querySelector('#listaProducto');
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }

        //para producto (cuando se hace click en producto)
        else if (TableTypes.LISTAR_PRODUCTO == callEvento.key) {
          //agregga cada prodcuto a la lista de pedido
          const prodSel: Producto = JSON.parse(
            JSON.stringify(callEvento.data as Producto)
          );
          if (prodSel.stock_ilimitado) {
            this.procesarAgregarProducto(prodSel);
          } else {
            if (prodSel.cantidad_stock && prodSel.cantidad_stock >= 1) {
              this.procesarAgregarProducto(prodSel);
            } else {
              this.uiService.ShowSnackBar(
                SnackBarType.ERROR,
                Msjs.MSJ_PRODUCTO_CANTIDAD_MINIMA_NECESARIA,
                3000
              );
              return;
            }
          }
        }
      }
    );

    //agregar producto a pedido
    this.showAgregarSb = this.uiService.showAgregarPedido.subscribe(
      (objSend: ISendPedidoRegistro) => {
        this.spinnerPageService.show();
        this.inicializarVariables(false, false);

        if (objSend.pedido) {
          const idPedido = objSend.pedido.id ? objSend.pedido.id : '';

          this.listaProductoSb = this.pedidoBLService
            .getProductosXPedido(objSend.idTurno, idPedido)
            .subscribe((listaProds) => {
              if (listaProds) {
                this.spinnerPageService.hide();
                this.listaProductoGuardadoPrevio = JSON.parse(
                  JSON.stringify(listaProds)
                );
              }
              this.esAgregar = true;
              this.showCatalogo = true;

              this.isDisabledMesa = true;
              this.pedido = JSON.parse(JSON.stringify(objSend.pedido));
            });
        }
      }
    );

    //cuando se requiere modificacion por parte del mesero EDITAR
    this.showRegisterSb = this.uiService.showRegistrarPedido.subscribe( (objSend: ISendPedidoRegistro) => {
        this.spinnerPageService.show();
        this.inicializarVariables(false, false);
        if (objSend.pedido) {
          const idPedido = objSend.pedido.id ? objSend.pedido.id : '';
          this.listaProductoSb = this.pedidoBLService
            .getProductosXPedido(objSend.idTurno, idPedido)
            .subscribe((listaProds) => {
              if (listaProds) {
                this.spinnerPageService.hide();
                this.listaProductoPedido = JSON.parse(
                  JSON.stringify(listaProds)
                );
              }
              this.esEditar = true;
              this.isDisabledMesa = true;
              this.showCatalogo = false;
              this.pedido = JSON.parse(JSON.stringify(objSend.pedido));
              this.varMesaSolicitado = this.pedido.ambiente_mesa.filter(
                (amb_mesa) =>
                  amb_mesa.estado_cambio &&
                  amb_mesa.estado_cambio == SolicitarCambioType.SOLICITADO &&
                  amb_mesa.es_vigente
              );
              if (this.varMesaSolicitado.length > 0) {
                this.esVigenteSolicitudMesa = true;
              }

              const solicitudProducto = this.listaProductoPedido.filter(
                (prod) =>
                  prod.estado_producto_vigente ==
                  EstadosProductoType.PENDIENTE_REVISION
              );
              if (solicitudProducto.length > 0) {
                this.esVigenteSolicitudProducto = true;
              }
            });
        }
      }
    );
  }

  changePedidoIncidencia(){
    this.incidenciaColaborador= new CColaborador();
    this.esPedidoIncidencia = !this.esPedidoIncidencia;
      this.loadingPedidoIncidencia = true;    
      this.gestionColaboradorBLService.getColaboradorXModulo(this.uiNavigateSide.goOptionSidenavItem).then(listaColab=>{
        if(listaColab!=null && listaColab?.length>0){
          this.listaColaboradoresInc = listaColab
        }
        this.loadingPedidoIncidencia = false;
      });
  }

  getProductoNoCombo() {
    return Utils.getProductoNoCombo();
  }

  getProductoCombo() {
    return Utils.getProductoCombo();
  }

  procesarSolicitudProducto(operacion: SistOperation) {
    this.historialVariacionProducto = [];
    if (operacion == SistOperation.PROCESAR_SOLICITUD) {
      let esOkProcesamiento: boolean = false;

      if (this.esEditar) {
        const strines = Utils.generarProductosVariados(
          this.listaProductoPedido
        );
        let msj =
          'Se solicitó una variación en los productos: Anterior(N° ' +
          strines[0] +
          ') ---> Solicitado(N° ' +
          strines[1] +
          ')';

        this.modalSb = this.uiService
          .showModalQuestions({
            title: Msjs.TITULO_CONFIRMACION,
            message: msj,
            icon: Icons.QUESTION_MARK,
          })
          .subscribe(async (result) => {
            //acepta el cambio
            await this.cargarFechas();
            this.spinnerPageService.show();
            const productosVariados: IProductoTicket[] =
              this.listaProductoPedido
                .filter(
                  (producto: IProductoTicket) =>
                    producto.es_eliminado || producto.es_modificado
                )
                .map((prod) => JSON.parse(JSON.stringify(prod)));

            //generando historial de pedido
            this.historialVariacionPedido = new EstadoPedido();
            this.historialVariacionPedido.es_vigente = true;
            this.historialVariacionPedido.fecha_key = { ...this.fechaHoy };
            this.historialVariacionPedido.usuario_aprobacion =
              this.registroService.usuario.correo_electronico;
            this.historialVariacionPedido.auto_numerico =
              Utils.generaMesHoraAutonumerico(this.fechaHoy);
            this.historialVariacionPedido.proceso = ProcesoType.VARIAR_PRODUCTO;

            if (result == true) {
              esOkProcesamiento = true;
              //parte de historial pedido
              this.historialVariacionPedido.estado_pedido =
                EstadosPedidoType.APROBADO;
              const estadosPermitidos = [
                EstadosProductoType.PENDIENTE,
                EstadosProductoType.EN_PROCESO,
              ];
              let pedidoTieneBebida: boolean = false;
              let pedidoTieneComida: boolean = false;

              //generando historial de productos
              productosVariados.forEach((producto: IProductoTicket) => {
                const historialProd: EstadosProducto = new EstadosProducto();
                historialProd.fecha = this.fechaHoy;
                historialProd.usuario_creacion =
                  this.registroService.usuario.correo_electronico;
                historialProd.proceso = ProcesoType.VARIAR_PRODUCTO;
                historialProd.usuario_aprobacion =
                  this.registroService.usuario.correo_electronico;
                historialProd.estado_cambio = SolicitarCambioType.APROBADO;
                historialProd.estado_producto = EstadosProductoType.PENDIENTE;
                historialProd.es_vigente = true;
                historialProd.auto_numerico = Utils.generaMesHoraAutonumerico(
                  this.fechaHoy
                );
                historialProd.codigo_ticket = producto.codigo_ticket;
                historialProd.nombre_producto = producto.nombre_producto;
                historialProd.codigo_producto = producto.codigo_producto;
                historialProd.cantidad_producto = producto.cantidad_producto;

                if (producto.es_modificado) {
                  //determina el tipo de producto
                  pedidoTieneBebida = Utils.getTipoProducto(true).includes(
                    producto.tipo_producto
                  );
                  pedidoTieneComida = Utils.getTipoProducto(false).includes(
                    producto.tipo_producto
                  );

                  historialProd.es_modificado = true;
                  historialProd.cantidad_producto_reducido =
                    producto.cantidad_producto_reducido;
                  //actualizando productos
                  producto.cantidad_producto =
                    producto.cantidad_producto -
                    producto.cantidad_producto_reducido!;
                  producto.estado_producto_vigente =
                    EstadosProductoType.PENDIENTE;
                  producto.precio_total =
                    Number(producto.cantidad_producto) *
                    Number(producto.precio_unitario);

                  //quitando propiedad adicional de caracteristicas anteriores si es que las tiene,
                  // se quita en la capa de acceso a datos
                  // if (
                  //   producto.lista_caract_seleccionada_individualizada_anterior
                  // ) {
                  //   delete producto.lista_caract_seleccionada_individualizada_anterior;
                  // }

                  for (let i = 0; i < this.listaProductoPedido.length; i++) {
                    if (
                      producto.codigo_producto ==
                      this.listaProductoPedido[i].codigo_producto
                    ) {
                      this.listaProductoPedido[i].cantidad_producto =
                        producto.cantidad_producto;
                      delete this.listaProductoPedido[i].es_modificado;
                    }
                  }
                }

                if (producto.es_eliminado) {
                  //determina el tipo de producto
                  pedidoTieneBebida = Utils.getTipoProducto(true).includes(
                    producto.tipo_producto
                  );
                  pedidoTieneComida = Utils.getTipoProducto(false).includes(
                    producto.tipo_producto
                  );

                  historialProd.es_eliminado = true;
                  //se quita el producto eliminado en la capa de acceso a datos
                  this.pedido.total_productos_pedidos =
                    this.pedido.total_productos_pedidos - 1;
                }
                this.historialVariacionProducto.push(historialProd);
              });
              //calculando el total del pedido
              const prodEliminado = productosVariados.filter(
                (prod) => prod.es_eliminado
              );
              for (let i = 0; i < prodEliminado.length; i++) {
                const prodRestantes = this.listaProductoPedido.filter(
                  (prod) =>
                    prod.codigo_producto !== prodEliminado[i].codigo_producto
                );
                this.listaProductoPedido = prodRestantes;
              }
              this.pedido.lista_producto = this.listaProductoPedido;
              this.calcularTotalTicket();

              //generando estados de producto general a nivel de pedido

              const tieneBebida = this.listaProductoPedido.filter(
                (prod) =>
                  Utils.getTipoProducto(true).includes(prod.tipo_producto) &&
                  estadosPermitidos.includes(prod.estado_producto_vigente)
              );
              const tieneComida = this.listaProductoPedido.filter(
                (prod) =>
                  Utils.getTipoProducto(false).includes(prod.tipo_producto) &&
                  estadosPermitidos.includes(prod.estado_producto_vigente)
              );
              if (
                (tieneBebida != undefined && tieneBebida.length > 0) ||
                pedidoTieneBebida
              ) {
                this.pedido.estado_pedido_vigente_bebida =
                  EstadosProductoType.PENDIENTE;
              }
              if (
                (tieneComida != undefined && tieneComida.length > 0) ||
                pedidoTieneComida
              ) {
                this.pedido.estado_pedido_vigente_comida =
                  EstadosProductoType.PENDIENTE;
              }

              //actualiza estado de pedido en el acceso a datos
            }
            //rechaza el cambio
            else {
              esOkProcesamiento = false;
              //parte de historial pedido
              this.historialVariacionPedido.estado_pedido =
                EstadosPedidoType.RECHAZADO;
              //generando historial de productos
              productosVariados.forEach((producto) => {
                const historialProd: EstadosProducto = new EstadosProducto();
                historialProd.fecha = this.fechaHoy;
                historialProd.usuario_creacion =
                  this.registroService.usuario.correo_electronico;
                historialProd.proceso = ProcesoType.VARIAR_PRODUCTO;
                historialProd.usuario_aprobacion =
                  this.registroService.usuario.correo_electronico;
                historialProd.estado_cambio = SolicitarCambioType.RECHAZADO;
                historialProd.estado_producto = EstadosProductoType.PENDIENTE;
                historialProd.es_vigente = true;
                historialProd.auto_numerico = Utils.generaMesHoraAutonumerico(
                  this.fechaHoy
                );
                historialProd.codigo_ticket = producto.codigo_ticket;
                historialProd.codigo_producto = producto.codigo_producto;
                historialProd.nombre_producto = producto.nombre_producto;
                historialProd.cantidad_producto = producto.cantidad_producto;

                if (producto.es_modificado) {
                  historialProd.es_modificado = true;
                  historialProd.cantidad_producto_reducido =
                    producto.cantidad_producto_reducido;

                  //retornando todo al estado anterior en caso de ser producto con caracteristicas
                  if (
                    producto.lista_caract_seleccionada_individualizada_anterior
                  ) {
                    //removemos todas las marca de producto eliminado en cada caracteristica
                    producto.lista_caract_seleccionada_individualizada_anterior.forEach(
                      (caracte) => {
                        if (caracte.es_eliminado) {
                          delete caracte.es_eliminado;
                        }
                      }
                    );
                    //igualamos las caracteristicas anteriores a las caract. actuales, para retornar al estado anterior del producto
                    producto.lista_caract_seleccionada_individualizada =
                      JSON.parse(
                        JSON.stringify(
                          producto.lista_caract_seleccionada_individualizada_anterior
                        )
                      ) as ICaracteristicaProducto[];
                    //totalizamos las caracteristicas
                    producto.lista_caract_seleccionada = Utils.totalizadorCaracteristicaProducto(producto.lista_caract_seleccionada_individualizada);
                    //quitando propiedad adicional de caracteristicas anteriores si es que las tiene,
                    // se quita en la capa de acceso a datos
                    //delete producto.lista_caract_seleccionada_individualizada_anterior;
                  }
                }

                if (producto.es_eliminado) {
                  historialProd.es_eliminado = true;
                }
                producto.estado_producto_vigente =
                  EstadosProductoType.PENDIENTE;
                this.historialVariacionProducto.push(historialProd);
              });
            }

            this.pedidoBLService
              .updateVariacionProducto(
                this.pedido,
                this.idTurno,
                esOkProcesamiento,
                this.historialVariacionPedido,
                this.historialVariacionProducto,
                productosVariados
              )
              .then((tx) => {
                if (tx.tx) {
                  this.uiService.ShowSnackBar(
                    SnackBarType.SUCCESS,
                    Msjs.MSJ_GUARDADO_OK,
                    3000
                  );
                  //limpiando el pedido
                  this.inicializarVariables(true, true);
                } else {
                  this.uiService.ShowSnackBar(
                    SnackBarType.ERROR,
                    tx.error,
                    3000
                  );
                  this.spinnerPageService.hide();
                }
              });
          });
      }
    }
  }

  procesarSolicitud(operacion: SistOperation, procesaMesa: boolean) {
    this.historialVariacionMesa = [];
    if (operacion == SistOperation.PROCESAR_SOLICITUD) {
      if (this.esEditar && procesaMesa) {
        let mesas_variadas: string[] = [];
        let mesas_acciones: string[] = [];
        this.varMesaSolicitado.forEach((mesas) => {
          mesas_variadas.push(mesas.mesa_numero.toString());
          mesas_acciones.push(mesas.accion_mesa);
        });

        let msj =
          'Se solicitó una variación en las mesas: Anterior(N° ' +
          Utils.generarMesasFila(this.pedido.mesas_completo) +
          ') ---> Solicitado(N° ' +
          Utils.generarMesasFila(mesas_variadas) +
          ' ' +
          Utils.generarMesasFila(mesas_acciones) +
          ')';

        this.modalSb = this.uiService
          .showModalQuestions({
            title: Msjs.TITULO_CONFIRMACION,
            message: msj,
            icon: Icons.QUESTION_MARK,
          })
          .subscribe(async (result) => {
            //obtenemos las variaciones solicitadas
            const mesa_solicitado: IAmbienteMesa[] = this.pedido.ambiente_mesa
              .filter(
                (amb_mesas) =>
                  amb_mesas.estado_cambio &&
                  amb_mesas.estado_cambio == SolicitarCambioType.SOLICITADO &&
                  amb_mesas.es_vigente
              )
              .map((amb_mesas) => JSON.parse(JSON.stringify(amb_mesas)));

            this.spinnerPageService.show();

            await this.cargarFechas();

            //generando historial de pedido
            this.historialVariacionPedido = new EstadoPedido();
            this.historialVariacionPedido.es_vigente = true;
            this.historialVariacionPedido.proceso =
              ProcesoType.CAMBIAR_VARIAR_MESA;
            this.historialVariacionPedido.usuario_aprobacion =
              this.registroService.usuario.correo_electronico;
            this.historialVariacionPedido.fecha_key = { ...this.fechaHoy };
            this.historialVariacionPedido.auto_numerico =
              Utils.generaMesHoraAutonumerico(this.fechaHoy);

            //aprueba peticion (añadir y/o quitar mesas)
            if (result == true) {
              //parte de historial pedido
              this.historialVariacionPedido.estado_pedido =
                EstadosPedidoType.APROBADO;

              //generamos traza actual(todos los vigentes)
              mesa_solicitado.forEach((amb_mesas) => {
                if (amb_mesas.accion_mesa == AccionMesaType.ANIADIR) {
                  //actualizando ambiente mesas
                  amb_mesas.fecha_key = { ...this.fechaHoy };
                  for (let i = 0; i <= this.pedido.ambiente_mesa.length; i++) {
                    if (
                      this.pedido.ambiente_mesa[i].mesa_numero ==
                        amb_mesas.mesa_numero &&
                      this.pedido.ambiente_mesa[i].ambiente ==
                        amb_mesas.ambiente &&
                      this.pedido.ambiente_mesa[i].estado_cambio &&
                      this.pedido.ambiente_mesa[i].estado_cambio ==
                        SolicitarCambioType.SOLICITADO
                    ) {
                      this.pedido.ambiente_mesa[i].estado_cambio =
                        SolicitarCambioType.APROBADO;
                      this.pedido.ambiente_mesa[i].mesa_estado =
                        EstadosMesaType.OCUPADO;
                      break;
                    }
                  }

                  //insertar en mesas completo
                  amb_mesas.mesa_estado = EstadosMesaType.OCUPADO;
                  //solo para las mesas que se añaden
                  amb_mesas.es_vigente = true;
                  //insertamos en mesas completo
                  this.pedido.mesas_completo.push(
                    amb_mesas.mesa_numero.toString()
                  );
                }
                //AccionMesaType.QUITAR
                else {
                  let indice = -1;
                  for (let i = 0; i <= this.pedido.ambiente_mesa.length; i++) {
                    if (
                      this.pedido.ambiente_mesa[i].mesa_numero ==
                        amb_mesas.mesa_numero &&
                      this.pedido.ambiente_mesa[i].ambiente ==
                        amb_mesas.ambiente &&
                      this.pedido.ambiente_mesa[i].estado_cambio &&
                      this.pedido.ambiente_mesa[i].estado_cambio ==
                        SolicitarCambioType.SOLICITADO
                    ) {
                      indice = i;
                      break;
                    }
                  }
                  if (indice != -1) {
                    this.pedido.ambiente_mesa.splice(indice, 1);
                  }

                  indice = -1;
                  for (let i = 0; i <= this.pedido.mesas_completo.length; i++) {
                    if (
                      this.pedido.mesas_completo[i] ==
                      amb_mesas.mesa_numero.toString()
                    ) {
                      indice = i;
                      break;
                    }
                  }
                  if (indice != -1) {
                    this.pedido.mesas_completo.splice(indice, 1);
                  }
                }
                //generando historial
                const historialMesa = JSON.parse(JSON.stringify(amb_mesas));
                historialMesa.auto_numerico = Utils.generaMesHoraAutonumerico(
                  this.fechaHoy
                );
                historialMesa.fecha_key = { ...this.fechaHoy };
                historialMesa.usuario_aprobacion =
                  this.registroService.usuario.correo_electronico;
                historialMesa.estado_cambio = SolicitarCambioType.APROBADO;
                this.historialVariacionMesa.push(historialMesa);
              });

              this.pedidoBLService
                .updateVariacionMesa(
                  this.pedido,
                  this.idTurno,
                  true,
                  this.fechaHoy,
                  this.historialVariacionMesa,
                  this.historialVariacionPedido
                )
                .then((tx) => {
                  if (tx.tx) {
                    this.uiService.ShowSnackBar(
                      SnackBarType.SUCCESS,
                      Msjs.MSJ_GUARDADO_OK,
                      3000
                    );
                    this.inicializarVariables(true, false);
                    this.spinnerPageService.hide();
                  } else {
                    this.uiService.ShowSnackBar(
                      SnackBarType.ERROR,
                      tx.error,
                      3000
                    );
                    this.spinnerPageService.hide();
                  }
                });
            }
            //rechaza peticion
            else {
              //parte de historial pedido
              this.historialVariacionPedido.estado_pedido =
                EstadosPedidoType.RECHAZADO;

              mesa_solicitado.forEach((amb_mesas) => {
                let indice = -1;
                for (let i = 0; i <= this.pedido.ambiente_mesa.length; i++) {
                  if (
                    this.pedido.ambiente_mesa[i].mesa_numero ==
                      amb_mesas.mesa_numero &&
                    this.pedido.ambiente_mesa[i].ambiente ==
                      amb_mesas.ambiente &&
                    this.pedido.ambiente_mesa[i].estado_cambio &&
                    this.pedido.ambiente_mesa[i].estado_cambio ==
                      SolicitarCambioType.SOLICITADO
                  ) {
                    indice = i;
                    break;
                  }
                }
                if (indice != -1) {
                  if (amb_mesas.accion_mesa == AccionMesaType.ANIADIR) {
                    this.pedido.ambiente_mesa.splice(indice, 1);
                  } else {
                    //cuando es quitar
                    this.pedido.ambiente_mesa[indice].accion_mesa =
                      AccionMesaType.ANIADIR;
                    this.pedido.ambiente_mesa[indice].mesa_estado =
                      EstadosMesaType.OCUPADO;
                    this.pedido.ambiente_mesa[indice].usuario_modificacion =
                      this.registroService.usuario.correo_electronico;
                    delete this.pedido.ambiente_mesa[indice].estado_cambio;
                    delete this.pedido.ambiente_mesa[indice].proceso;
                  }
                }

                //generando historial
                const historialMesa = JSON.parse(JSON.stringify(amb_mesas));
                historialMesa.auto_numerico = Utils.generaMesHoraAutonumerico(
                  this.fechaHoy
                );
                historialMesa.fecha_key = { ...this.fechaHoy };
                historialMesa.usuario_aprobacion =
                  this.registroService.usuario.correo_electronico;
                historialMesa.estado_cambio = SolicitarCambioType.RECHAZADO;
                this.historialVariacionMesa.push(historialMesa);
              });

              this.pedidoBLService
                .updateVariacionMesa(
                  this.pedido,
                  this.idTurno,
                  false,
                  this.fechaHoy,
                  this.historialVariacionMesa,
                  this.historialVariacionPedido
                )
                .then((tx) => {
                  if (tx.tx) {
                    this.uiService.ShowSnackBar(
                      SnackBarType.SUCCESS,
                      Msjs.MSJ_GUARDADO_OK,
                      3000
                    );
                    //limpiando el pedido
                    this.Limpiar();
                    this.spinnerPageService.hide();
                    this.esVigenteSolicitudMesa = false;
                    this.esVigenteSolicitudProducto = false;
                    this.esEditarMesa = false;
                    this.esEditar = false;
                    this.pedido = new Pedido();
                    this.fechaHoy = new Fecha();
                    this.visibleNuevoPedido = true;
                    this.uiService.showListarPedido.next(); //redirige al listado de prod
                  } else {
                    this.uiService.ShowSnackBar(
                      SnackBarType.ERROR,
                      tx.error,
                      3000
                    );
                    this.spinnerPageService.hide();
                  }
                });
            }
          });
      }
    }
  }

  showDialogCaracteristicaResult(producto: IProducto): Promise<any> {
    const modalCaract = this.uiService.showDialogCaractProductoListaInto({
      dato: producto,
    });
    return modalCaract.afterClosed().toPromise();
  }

  async procesarAgregarProducto(producto: IProducto) {
    console.log(producto);
    let caracteristica_seleccionada: string[] = [];
    let caracteristica_seleccionada_combo: ICaracteristicaProducto[] = [];
    let productoComboEsBebida: boolean = false;
    let productoComboEsComida: boolean = false;

    //validando que se tenga stock del producto
    if (!producto.stock_ilimitado && producto.cantidad_stock) {
      if (producto.cantidad_stock == 0) {
        this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_SIN_STOCK_PRODUCTO, 3000);
        return;
      }
    }

    //validando que se tenga mesas seleccionadas
    if (this.pedido.ambiente_mesa.length == 0) {
      this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_SIN_MESA, 3000);
      return;
    }

    //verificando que tiene caracteristicas adicionales para un producto que no es combo
    if ( producto.tiene_caracteristicas && producto.tipo_producto != ListaTipoProducto.listaTipoProducto[6].codigo_producto) {
      //mostrar dialog de caracteristicas
      const caracteristicasAdd  = await this.showDialogCaracteristicaResult(producto)
      if ( caracteristicasAdd.lista_caracteristicas == undefined ) {
        this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_NECESARIA_CARACTERISTICA, 3000);
        return;
      }else{
        caracteristica_seleccionada =  caracteristicasAdd.lista_caracteristicas;
      }
      
    }

    //producto tipo combo con caracteristicas adicionales
    if ( producto.tipo_producto == ListaTipoProducto.listaTipoProducto[6].codigo_producto ) {
      let listaProdConCaract = producto.subproductos!.filter( (prod) => prod.producto.tiene_caracteristicas);
      let listaProdSinCaract = producto.subproductos!.filter( (prod) => !prod.producto.tiene_caracteristicas);
      //cuando el producto tiene caracteristicas
      if (listaProdConCaract != undefined && listaProdConCaract.length > 0) {
      
        for(let i=0; i<listaProdConCaract.length;i++){
          //si producto tiene mas de una caracteristica seleccionable
          if(listaProdConCaract[i].producto.cantidad_caracteristicas_seleccionables!=undefined){
            const caracteristicasAdd:any  = await this.showDialogCaracteristicaResult(listaProdConCaract[i].producto);
            if(caracteristicasAdd.lista_caracteristicas == undefined){
              this.uiService.ShowSnackBar( SnackBarType.ERROR, Msjs.MSJ_ERROR_NECESARIA_CARACTERISTICA, 5000);
              return;
            }else{
              //si existiera mas de un subproducto igual en el producto principal, se unifican todas las subcaracteristicas
              for(let j=0; j<caracteristicasAdd.lista_caracteristicas.length; j++){
                let existeCaractSel = caracteristica_seleccionada_combo.findIndex(caracSel=>caracteristicasAdd.lista_caracteristicas[j]==caracSel.caracteristica_seleccionada);
                if(existeCaractSel!=-1){
                  caracteristica_seleccionada_combo[existeCaractSel].cantidad+=1;
                }else{
                  const caracteristica: ICaracteristicaProducto = {
                    caracteristica_seleccionada: caracteristicasAdd.lista_caracteristicas[j],
                    tipo_producto: listaProdConCaract[i].producto.tipo_producto,
                    codigo_producto: listaProdConCaract[i].producto.codigo_producto,
                    cantidad: listaProdConCaract[i].cantidad!,
                    nombre_producto: listaProdConCaract[i].producto.nombre_producto,
                  };     
                  caracteristica_seleccionada_combo.push(caracteristica);
                
                }
              }              
            }
          }
          else{
          //si producto tiene una sola caracteristca
          caracteristica_seleccionada_combo = await this.showDialogCaracteristicaComboResult(producto);
          if (caracteristica_seleccionada_combo == undefined) {
            this.uiService.ShowSnackBar( SnackBarType.ERROR, Msjs.MSJ_ERROR_NECESARIA_CARACTERISTICA, 5000);
            return;
          }
          }
        
        }
        
      } else {
        producto.subproductos!.forEach((product) => {

          //damos la forma del objeto caracteristica
          const caracteristicaFicticia: ICaracteristicaProducto = {
            caracteristica_seleccionada: '',
            tipo_producto: product.producto.tipo_producto,
            codigo_producto: product.producto.codigo_producto,
            cantidad: product.cantidad!,
            nombre_producto: product.producto.nombre_producto,
          };
          caracteristica_seleccionada_combo.push(caracteristicaFicticia);
        });
      }
      //cuando el producto no tiene caracteristicas
      if(listaProdSinCaract!=undefined && listaProdConCaract.length>0){
        listaProdSinCaract.forEach(produS=>{
            //damos la forma del objeto caracteristica
            const caracteristicaFicticia: ICaracteristicaProducto = {
              caracteristica_seleccionada: '',
              tipo_producto: produS.producto.tipo_producto,
              codigo_producto: produS.producto.codigo_producto,
              cantidad: produS.cantidad!,
              nombre_producto: produS.producto.nombre_producto,
            };
            caracteristica_seleccionada_combo.push(caracteristicaFicticia);
        });
      }

      //tipando el producto
      producto.subproductos!.forEach((produ) => {
        const tipoProdCombo = produ.producto.tipo_producto;
        if (Utils.getTipoProducto(true).includes(tipoProdCombo)) {
          productoComboEsBebida = true;
        }

        if (Utils.getTipoProducto(false).includes(tipoProdCombo)) {
          productoComboEsComida = true;
        }
      });
    }

    //añadir unidad a producto previamente seleccionado
    if (this.listaProductoPedido.length > 0) {
      let productoEncontrado: boolean = false;
      for (let i = 0; i < this.listaProductoPedido.length; i++) {
        if ( this.listaProductoPedido[i].codigo_producto == producto.codigo_producto) {
          productoEncontrado = true;
          this.listaProductoPedido[i].cantidad_producto += 1;
          this.calcularTotalTicket();
          this.uiService.ShowSnackBar( SnackBarType.INFO,  Msjs.MSJ_PRODUCTO_AGREGADO_CANTIDAD + this.listaProductoPedido[i].nombre_producto, 3000);

          //verificando que tiene caracteristicas adicionales
          if (producto.tiene_caracteristicas) {
            //producto que no es combo
            if ( producto.tipo_producto !== ListaTipoProducto.listaTipoProducto[6].codigo_producto ) {
              
              //generamos la lista inicial
              const mapaCaracteristicas =  new Map<string,ICaracteristicaProducto>();
              for(const caracA of this.listaProductoPedido[i].lista_caract_seleccionada!){
                mapaCaracteristicas.set(caracA.caracteristica_seleccionada,{...caracA});
              }
              //recorremos la lista b
              for(const caracB of caracteristica_seleccionada){
                const existente = mapaCaracteristicas.get(caracB);
                if(existente){
                  existente.cantidad+=1;
                  mapaCaracteristicas.set(caracB,existente);
                }else{
                  const objCaractSeleccionada: ICaracteristicaProducto = {
                    caracteristica_seleccionada: caracB,
                    tipo_producto: producto.tipo_producto,
                    codigo_producto: producto.codigo_producto,
                    cantidad: 1,
                  };

                  mapaCaracteristicas.set(caracB,{...objCaractSeleccionada});
                }
              }
              //seteamos todas las caracteristicas resultantes
              this.listaProductoPedido[i].lista_caract_seleccionada = Array.from(mapaCaracteristicas.values());                        

              //añadiendo codigo identificador de grupo_producto individualizada
              const codigoGrupoProducto = Utils.generarCodigoRandom(6);
              caracteristica_seleccionada.forEach(caract=>{
                const objCaractSeleccionadaIndividual: ICaracteristicaProducto = {
                  caracteristica_seleccionada: caract,
                  tipo_producto: producto.tipo_producto,
                  codigo_producto: producto.codigo_producto,
                  cantidad: 1,
                  grupo_producto: codigoGrupoProducto,
                };
                this.listaProductoPedido[i].lista_caract_seleccionada_individualizada?.push(objCaractSeleccionadaIndividual);  
              });              
            }
          }

          //producto tipo combo agregado 2 veces unifica la cantidades
          if ( producto.tipo_producto === ListaTipoProducto.listaTipoProducto[6].codigo_producto) {
            let lista_caracteristicasExistentes!:ICaracteristicaProducto[] | undefined;

            caracteristica_seleccionada_combo.forEach((carCombo, index: number) => {
                lista_caracteristicasExistentes = JSON.parse(JSON.stringify(this.listaProductoPedido[i].lista_caract_seleccionada));
                if (lista_caracteristicasExistentes != undefined) {
                  const indexExist = lista_caracteristicasExistentes.findIndex(
                    (caract: ICaracteristicaProducto) =>
                      caract.codigo_producto === carCombo.codigo_producto &&
                      caract.caracteristica_seleccionada === carCombo.caracteristica_seleccionada
                  );
                  if (indexExist != -1) {
                    lista_caracteristicasExistentes[indexExist].cantidad +=carCombo.cantidad;
                    this.listaProductoPedido[i].lista_caract_seleccionada = lista_caracteristicasExistentes;
                  } else {
                    this.listaProductoPedido[i].lista_caract_seleccionada?.push(
                      {
                        caracteristica_seleccionada:caracteristica_seleccionada_combo[index].caracteristica_seleccionada,
                        cantidad:caracteristica_seleccionada_combo[index].cantidad,
                        codigo_producto:caracteristica_seleccionada_combo[index].codigo_producto,
                        tipo_producto:caracteristica_seleccionada_combo[index].tipo_producto,
                        nombre_producto:caracteristica_seleccionada_combo[index].nombre_producto,
                      }
                    );
                  }
                }
              }
            );

            const codigoGrupoProductoCombo = Utils.generarCodigoRandom(6);
            caracteristica_seleccionada_combo.forEach(
              (carCombo, index: number) => {
                this.listaProductoPedido[i].lista_caract_seleccionada_individualizada?.push({
                  caracteristica_seleccionada: caracteristica_seleccionada_combo[index].caracteristica_seleccionada,
                  cantidad:         caracteristica_seleccionada_combo[index].cantidad,
                  codigo_producto:  caracteristica_seleccionada_combo[index].codigo_producto,
                  tipo_producto:    caracteristica_seleccionada_combo[index].tipo_producto,
                  nombre_producto:  caracteristica_seleccionada_combo[index].nombre_producto,
                  grupo_producto:   codigoGrupoProductoCombo,
                });
              }
            );
          }
        }
        if (productoEncontrado) {
          break;
        }
      }
      if (productoEncontrado) {
        return;
      }
    }

    const msj = Msjs.MSJ_AGREGASTE_OK.replace('$', producto.nombre_producto);
    this.uiService.ShowSnackBar(SnackBarType.SUCCESS, msj, 3000);
    this.nombreProductoComboSel = producto.nombre_producto;

    if (this.codigoTicket == '') {
      this.codigoTicket = Utils.generarCodigoRandom(6);
    }
    if (this.fechaHoy.anio == '') {
      await this.cargarFechas();
    }
    
    //calcula el valor del producto antes del impuesto, y retorna el array de valor de producto y su impuesto, necesario para comprobante electornico
    const valorProdImpuesto:number[] = Utils.calcularValorProductoImpuesto(Number(producto.precio_unitario),this.aspectosGenerales.tasa_impuesto);

    const productoTicket: IProductoTicket = {
      fecha_key:this.fechaHoy,// para determinar el tiempo de pedido
      auto_numerico: Utils.generaMesHoraAutonumerico(this.fechaHoy),
      codigo_ticket: this.codigoTicket,
      cantidad_producto: 1,
      codigo_producto: producto.codigo_producto,
      nombre_producto: producto.nombre_producto,
      valor_producto: valorProdImpuesto[1],//valor real de producto
      igv_valor_producto: valorProdImpuesto[0],//igv producto
      precio_unitario: Number(producto.precio_unitario),
      precio_total: Number(producto.precio_unitario),
      estado_producto_vigente: EstadosProductoType.PENDIENTE,
      tipo_producto: producto.tipo_producto,
      usuario_creacion: this.registroService.usuario.correo_electronico,
      usuario_creacion_busqueda: this.registroService.usuario.correo_electronico.split('@')[0], //sirve para las busquedas por usuario
      reducir_cantidad: producto.stock_ilimitado ? false : true, //sirve para reducir la cantidad si tiene el stock limitado
      id_producto: producto.id ? producto.id : '', //sirve para actualizar el documento de producto
      //...(producto.tiene_caracteristicas && {caracteristica_seleccionada:caracteristica_seleccionada})
    };

    //agregndo nueva caracteristica a producto que no es combo
    if (producto.tiene_caracteristicas) {
      if ( producto.tipo_producto !== ListaTipoProducto.listaTipoProducto[6].codigo_producto) {
        //añadiendo caractertistica individualizada para producto que no es combo
        const codigoGrupoProductoCombo = Utils.generarCodigoRandom(6);

        let listaCaract: ICaracteristicaProducto[] = [];
        caracteristica_seleccionada.forEach(caract=>{
          const objCaractSeleccionada: ICaracteristicaProducto = {
            caracteristica_seleccionada: caract,
            tipo_producto: producto.tipo_producto,
            codigo_producto: producto.codigo_producto,
            cantidad: 1,
            grupo_producto: codigoGrupoProductoCombo
          };
          
          listaCaract.push(objCaractSeleccionada);
        });       
        productoTicket.lista_caract_seleccionada = listaCaract;        
        const copiaCaracteristicas = JSON.parse( JSON.stringify(listaCaract)) as ICaracteristicaProducto[];       
        productoTicket.lista_caract_seleccionada_individualizada = copiaCaracteristicas;
      }
    }

    if (producto.tipo_producto ==ListaTipoProducto.listaTipoProducto[6].codigo_producto) {
      productoTicket.lista_caract_seleccionada = caracteristica_seleccionada_combo; //contiene caracteristicas totalizadas

      //añadiendo codigo identificador de grupo_producto individualizada, sirve para poder separar en caso se requiera (descuentos e incidencias) a varios productos tipo combo
      const codigoGrupoProductoCombo = Utils.generarCodigoRandom(6);
      const copiaCaracteristicasCombo = JSON.parse(
        JSON.stringify(caracteristica_seleccionada_combo)
      ) as ICaracteristicaProducto[];
      copiaCaracteristicasCombo.forEach(
        (prod) => (prod.grupo_producto = codigoGrupoProductoCombo)
      );

      productoTicket.lista_caract_seleccionada_individualizada =
        copiaCaracteristicasCombo; //contiene caracteristicas individuales
      //seteando lo nuevos estados si es combo de comidas, bebidas o mixto
      if (productoComboEsBebida) {
        productoTicket.estado_producto_vigente_bebida =
          EstadosProductoType.PENDIENTE;
      }
      if (productoComboEsComida) {
        productoTicket.estado_producto_vigente_comida =
          EstadosProductoType.PENDIENTE;
      }
    }

    this.listaProductoPedido.push(productoTicket);    
    this.calcularTotalTicket();
    this.visibleNuevoPedido = false;
  }

  mostrarProductoCompuesto(producto: IProductoTicket) {
    if (producto.cantidad_producto == 1) {    
      this.uiService.ShowSnackBar(
        SnackBarType.WARNING,
        Msjs.MSJ_PRODUCTO_CANTIDAD_MINIMA,
        3000
      );
      return;
    }else{
      if(this.esEditar && producto.cantidad_producto_reducido){
        let cantidadRestante =0;
        if(this.esAdministrador){
          cantidadRestante = producto.cantidad_producto;
        }else{
          cantidadRestante = producto.cantidad_producto - producto.cantidad_producto_reducido!
        }        
        if(cantidadRestante===1){
          this.uiService.ShowSnackBar(
            SnackBarType.WARNING,
            Msjs.MSJ_PRODUCTO_CANTIDAD_MINIMA,
            3000
          );
          return;
        }        
      }
    }

    const prodCompuesto = this.uiService.showDialogProductoCompuesto({
      producto: producto,
      tipo_pago: TipoOperacionProductoCompuesto.REGISTRAR,
    });
    prodCompuesto.afterClosed().subscribe((result) => {     
      //actualizando el pedido con descuento modificado, ya que el observable pasado por datoPasado, no se actualiza
      if (result && result.lista_eliminados.length > 0) {
        for (let i = 0; i < this.listaProductoPedido.length; i++) {          
        
          if (
            this.listaProductoPedido[i].codigo_producto ==
              producto.codigo_producto &&
            this.listaProductoPedido[i].codigo_ticket == producto.codigo_ticket
          ) {
            // grupos de caracteristicas restantes
            const caractFiltradas = this.listaProductoPedido[
              i
            ].lista_caract_seleccionada_individualizada!.filter(
              (caract) =>
                !result.lista_eliminados.includes(caract.grupo_producto)
            );

            //cuando esta registrando el pedido,
            if(!this.esEditar){
              this.listaProductoPedido[i].cantidad_producto = this.listaProductoPedido[i].cantidad_producto - 1;
              // obteniendo las caracteristicas restantes al eliminado
              this.listaProductoPedido[i].lista_caract_seleccionada_individualizada = caractFiltradas;
              //limpiando caracteristicas totalizadas:
              this.listaProductoPedido[i].lista_caract_seleccionada = [];
              this.listaProductoPedido[i].lista_caract_seleccionada = Utils.totalizadorCaracteristicaProducto(caractFiltradas);
              //indicamos que se modifico la cantidad de producto
              this.calcularTotalTicket();
              return;
            }
            //calculando el total de producto restante
            this.listaProductoPedido[i].es_modificado = true;
            this.listaProductoPedido[i].cantidad_producto_reducido = this
              .listaProductoPedido[i].cantidad_producto_reducido
              ? this.listaProductoPedido[i].cantidad_producto_reducido +
                result.lista_eliminados.length
              : result.lista_eliminados.length;

            //reduciendo la cantidad de manera directa
            if (this.esAdministrador) {
              this.listaProductoPedido[i].cantidad_producto =
                this.listaProductoPedido[i].cantidad_producto - 1;
            }
            //guardando caracteristicas previas
            if (!this.esAdministrador) {
              if (
                this.listaProductoPedido[i]
                  .lista_caract_seleccionada_individualizada_anterior ===
                undefined
              ) {
                this.listaProductoPedido[
                  i
                ].lista_caract_seleccionada_individualizada_anterior =
                  this.listaProductoPedido[
                    i
                  ].lista_caract_seleccionada_individualizada;
                result.lista_eliminados.forEach((codigoGrupo: string) => {
                  this.listaProductoPedido[
                    i
                  ].lista_caract_seleccionada_individualizada_anterior?.forEach(
                    (caract) => {
                      if (caract.grupo_producto === codigoGrupo) {
                        caract.es_eliminado = true;
                      }
                    }
                  );
                });
              } else {
                this.listaProductoPedido[
                  i
                ].lista_caract_seleccionada_individualizada_anterior?.forEach(
                  (caract) => {
                    //siempre retornara un solo codigo, se cambio porque cierra el modal ante elimincion de un producto
                    if (caract.grupo_producto === result.lista_eliminados[0]) {
                      caract.es_eliminado = true;
                    }
                  }
                );
              }
            }
            // obteniendo las caracteristicas restantes al eliminado
            this.listaProductoPedido[i].lista_caract_seleccionada_individualizada = caractFiltradas;
            //limpiando caracteristicas totalizadas:
            this.listaProductoPedido[i].lista_caract_seleccionada = [];

            this.listaProductoPedido[i].lista_caract_seleccionada = Utils.totalizadorCaracteristicaProducto(caractFiltradas);

            //indicamos que se modifico la cantidad de producto
            this.calcularTotalTicket();
            /* reusar el modal para descuento e incidencia */
          }
        }
      }
    });
  }

  showDialogCaracteristicaComboResult(
    producto: IProducto
  ): Promise<ICaracteristicaProducto[]> {
    const modalCaract = this.uiService.showDialoListarProdComboCaract({
      dato: producto,
    });
    return modalCaract.afterClosed().toPromise();
  }

  //minibuscadores autocomplete
  buscarProductoInto() {
    const dialogRef = this.uiService.showModalBuscarProductoInto({
      catalogo: this.catalogoSeleccionado,
    });
    dialogRef.afterClosed().subscribe((producto: IProducto) => {
      if (producto) {
        this.procesarAgregarProducto(producto);
      }
    });
  }

  buscarCatalogoInto() {
    const dialogRef = this.uiService.showModalBuscarCatalogoInto({
      data: null,
    });
    dialogRef.afterClosed().subscribe((catalogo: ICatalogo) => {
      if (catalogo) {
        //simulando carga de producto
        this.catalogoSeleccionado = catalogo as Catalogo;
        const callEvento: ITableEvento = {
          key: TableTypes.LISTAR_CATALOGO,
          data: catalogo,
        };

        //el componente app-listar-producto reacciona a este subscription
        this.tableService.btnVer$.next(callEvento);
        this.matTabGroupPed.selectedIndex = 1;
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  async cargarFechas() {
    const fechaServer = moment(await this.functionService.GetDateServer())
      .local()
      .format();
    const fechaServerProcesado = Utils.separarFechasHoras(fechaServer);
    this.fechaHoy.anio = fechaServerProcesado[0]; //anio
    this.fechaHoy.mes = fechaServerProcesado[1]; //mes
    this.fechaHoy.dia = fechaServerProcesado[2]; //dia
    this.fechaHoy.hora = fechaServerProcesado[3]; //hora
    this.fechaHoy.minuto = fechaServerProcesado[4]; //minuto
    this.fechaHoy.segundo = fechaServerProcesado[5]; //seg
    if (!this.esEditar) {
      this.pedido.fecha_key = this.fechaHoy;
    }
  }

  redimensionaPantalla() {
    if (this.tamanioPantalla === 'xs') {
      this.esPantallaXs = true;
    } else {
      this.esPantallaXs = false;
    }
  }

  nuevoPedido() {
    this.uiService.showModalListarAmbienteMesaD({esEditar:this.esEditar, tipoTabla:this.tipoTabla} );
  }

  inicializarVariables(
    mostrarListaPedido: boolean = false,
    desuscribirListaProd: boolean = false
  ) {
    this.pedido = new Pedido();
    this.fechaHoy = new Fecha();
    this.visibleNuevoPedido = true;
    this.esEditarMesa = false;
    this.esEditarProducto = false;
    this.esEditar = false;
    this.isDisabledMesa = false;
    this.listaProductoPedido = [];
    this.listaProductoEliminado = [];
    this.historialVariacionProducto = [];
    this.historialVariacionPedido = new EstadoPedido();
    this.historialVariacionMesa = [];
    this.esVigenteSolicitudMesa = false;
    this.esVigenteSolicitudProducto = false;
    this.listaProductoGuardadoPrevio = [];
    this.nombreCatalogoSel='';
    this.esAgregar = false;
    this.showCatalogo = false;
    this.esNuevo = false;
    this.nombreProductoComboSel = '';
    this.esPedidoIncidencia = false;
    if (mostrarListaPedido) {
      this.uiService.showListarPedido.next();
    }
    if (desuscribirListaProd) {
      if (this.listaProductoSb) {
        this.listaProductoSb.unsubscribe();
      }
    }
  }

  cancelarPedido() {
    if (this.esEditar || this.esAgregar) {
      this.inicializarVariables();
    } else {
      if (this.pedido.ambiente_mesa.length > 0) {
        for (const config of this.pedido.ambiente_mesa) {
          this.removerMesa(config);
        }
      } else {
        this.contraerPedido();
      }
    }
  }

  contraerPedido() {
    //posicionar la pantalla en este elemento
    const element = this.el.nativeElement.querySelector('#comanda');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  cargarTasas() {
    this.gestionAspectosBL.existFetchTasas();
    if (this.tasasSb) {
      this.tasasSb.unsubscribe();
    }
    this.tasasSb = this.gestionAspectosBL.getTasas().subscribe((tasas) => {
      if (tasas) {
        this.aspectosGenerales = tasas;
      }
    });
  }

  removerMesa(config: IAmbienteMesa) {
    let indice: number = -1;
    let mesaAmbiente!: IAmbienteMesa;
    this.modalSb = this.uiService
      .showModalQuestions({
        title: Msjs.TITULO_CONFIRMACION,
        message: Msjs.MENSAJE_CONFIRMACION_ELIMINAR_MESA,
        icon: Icons.QUESTION_MARK,
      })
      .subscribe((result) => {
        if (result) {
          //editar pedido
          if (this.esEditar) {
            //cuando la mesa se añade pero tiene el estado solicitado en memoria
            if (
              config.estado_cambio === SolicitarCambioType.SOLICITADO &&
              config.accion_mesa === AccionMesaType.ANIADIR &&
              config.mesa_estado === EstadosMesaType.RESERVADO &&
              config.proceso === ProcesoType.CAMBIAR_VARIAR_MESA
            ) {
              let indiceSoli = -1;
              for (let i = 0; i < this.pedido.ambiente_mesa.length; i++) {
                if (
                  this.pedido.ambiente_mesa[i].ambiente === config.ambiente &&
                  this.pedido.ambiente_mesa[i].mesa_numero ===
                    config.mesa_numero
                ) {
                  indiceSoli = i;
                  break;
                }
              }
              //removemos la mesa ya que el cambio fue solo en memoria
              this.pedido.ambiente_mesa.splice(indiceSoli, 1);
              const mesaUpdDisponible: IAmbienteMesa = JSON.parse(
                JSON.stringify(config)
              );
              mesaUpdDisponible.mesa_estado = EstadosMesaType.DISPONIBLE;
              this.gestionAmbienteBLService.updateEstadoMesa(mesaUpdDisponible);
            } else {
              const mesasRestantes = this.pedido.ambiente_mesa.filter(
                (objMesa) => objMesa.accion_mesa === AccionMesaType.ANIADIR
              );

              if (mesasRestantes.length == 1) {
                this.uiService.ShowSnackBar(
                  SnackBarType.INFO,
                  Msjs.MSJ_SIN_MESA,
                  5000
                );
                return;
              }

              this.pedido.ambiente_mesa.forEach(async (ambMesa, index) => {
                if (
                  ambMesa.ambiente === config.ambiente &&
                  ambMesa.mesa_numero === config.mesa_numero
                ) {
                  indice = index;
                  ambMesa.mesa_estado = EstadosMesaType.DISPONIBLE;
                  ambMesa.accion_mesa = AccionMesaType.QUITAR;
                  ambMesa.es_vigente = true;
                  ambMesa.estado_cambio = this.esAdministrador
                    ? SolicitarCambioType.APROBADO
                    : SolicitarCambioType.SOLICITADO;
                  ambMesa.proceso = ProcesoType.CAMBIAR_VARIAR_MESA;

                  if (this.esAdministrador) {
                    if (this.fechaHoy.dia == '') {
                      await this.cargarFechas();
                    }
                  }

                  //generamos historial
                  mesaAmbiente = JSON.parse(JSON.stringify(ambMesa));

                  mesaAmbiente.auto_numerico = Utils.generaMesHoraAutonumerico(
                    this.fechaHoy
                  );
                  mesaAmbiente.fecha_key = { ...this.fechaHoy };
                  mesaAmbiente.usuario_aprobacion =
                    this.registroService.usuario.correo_electronico;
                  mesaAmbiente.estado_cambio = SolicitarCambioType.APROBADO;

                  //agregamos al historial
                  this.historialVariacionMesa.push(mesaAmbiente);
                }
              });
            }
            return;
          }
          //nuevo pedido
          else {
            this.pedido.ambiente_mesa.forEach((ambMesa, index) => {
              if (
                ambMesa.ambiente === config.ambiente &&
                ambMesa.mesa_numero === config.mesa_numero
              ) {
                indice = index;
                mesaAmbiente = ambMesa;
                mesaAmbiente.mesa_estado = EstadosMesaType.DISPONIBLE;
              }
            });
            //deselecionar mesa
            this.listarAmbienteMesaService.deseleccionarMesa$.next(
              {mesaAmbiente:mesaAmbiente, tipoTabla: this.tipoTabla}
            );
            this.pedido.ambiente_mesa.splice(indice, 1);
            this.gestionAmbienteBLService.updateEstadoMesa(mesaAmbiente);
            if (this.pedido.ambiente_mesa.length == 0) {
              this.pedido = new Pedido();
              this.contraerPedido();
              this.visibleNuevoPedido = true;
              this.showCatalogo = false;
              this.uiService.ShowSnackBar(SnackBarType.INFO, Msjs.MENSAJE_CONFIRMACION_CONFIRMAR_LIBERAR_MESA, 3000);
            }
          }
        }
      });
  }

  addProducto(producto: IProductoTicket) {
    this.listaProductoPedido.forEach((prod) => {
      if (prod.codigo_producto === producto.codigo_producto) {
        prod.cantidad_producto += 1;
        this.calcularTotalTicket();
      }
    });
  }

  changeCantidad(producto: IProductoTicket) {
    if (producto.cantidad_producto == 0) {
      this.uiService.ShowSnackBar(
        SnackBarType.WARNING,
        Msjs.MSJ_PRODUCTO_CANTIDAD_MINIMA_NECESARIA,
        3000
      );
      this.listaProductoPedido.forEach((prod) => {
        if (producto.codigo_producto == prod.codigo_producto) {
          prod.cantidad_producto = 1;
          prod.precio_total = prod.cantidad_producto * prod.precio_unitario;
          this.calcularTotalTicket();
        }
      });
      return;
    }
    this.listaProductoPedido.forEach((prod) => {
      if (producto.codigo_producto == prod.codigo_producto) {
        prod.cantidad_producto = producto.cantidad_producto;
        prod.precio_total = prod.cantidad_producto * prod.precio_unitario;
        this.calcularTotalTicket();
      }
    });
  }

  cargarCantidad(index: number) {
    //muestra el modal de producto compuesto
    if((this.listaProductoPedido[index].estado_producto_vigente=== EstadosProductoType.EN_PROCESO ||
      this.listaProductoPedido[index].estado_producto_vigente=== EstadosProductoType.TERMINADO) &&
      this.esEditarProducto==true
    ) {
      this.uiService.ShowSnackBar(
        SnackBarType.INFO,
        Msjs.MSJ_PRODUCTO_NO_PENDIENTE,
        3000
      );
      return;
    }
    if (
      this.listaProductoPedido[index].lista_caract_seleccionada != undefined &&
      this.listaProductoPedido[index].estado_producto_vigente ===
        EstadosProductoType.PENDIENTE_REVISION
    ) {
      this.mostrarProductoCompuesto(this.listaProductoPedido[index]);
      return;
    }
    if (this.listaProductoPedido[index].es_pagado != undefined) {
      this.uiService.ShowSnackBar(
        SnackBarType.INFO,
        Msjs.MSJ_PRODUCTO_PREPAGADO,
        3000
      );
      return;
    }
    if (this.listaProductoPedido[index].lista_caract_seleccionada && !this.esEditarProducto && !this.esEditar) {
      this.uiService.ShowSnackBar(
        SnackBarType.INFO,
        Msjs.MSJ_INFO_PRODUCTO_PERSONALIZABLE,
        3000
      );
      return;
    }
    if (this.esEditarProducto && this.esEditar) {
      if (this.listaProductoPedido[index].descuento !== undefined) {
        this.uiService.ShowSnackBar(
          SnackBarType.INFO,
          Msjs.MSJ_PRODUCTO_DESCUENTO,
          3000
        );
        return;
      }
      if (this.listaProductoPedido[index].incidencia !== undefined) {
        this.uiService.ShowSnackBar(
          SnackBarType.INFO,
          Msjs.MSJ_PRODUCTO_INCIDENCIA,
          3000
        );
        return;
      }

      if (
        this.listaProductoPedido[index].estado_producto_vigente ===
        EstadosProductoType.TERMINADO
      ) {
        this.uiService.ShowSnackBar(
          SnackBarType.WARNING,
          Msjs.MSJ_PRODUCTO_TERMINADO,
          3000
        );
        return;
      }

      if (
        this.listaProductoPedido[index].estado_producto_vigente ===
        EstadosProductoType.EN_PROCESO
      ) {
        this.uiService.ShowSnackBar(
          SnackBarType.WARNING,
          Msjs.MSJ_PRODUCTO_EN_PROCESO,
          3000
        );
        return;
      }
      if (this.listaProductoPedido[index].es_eliminado) {
        this.uiService.ShowSnackBar(
          SnackBarType.WARNING,
          Msjs.MSJ_EDICION_PROCESO,
          3000
        );
        return;
      }
      if (this.listaProductoPedido[index].cantidad_producto == 1) {
        this.uiService.ShowSnackBar(
          SnackBarType.WARNING,
          Msjs.MSJ_PRODUCTO_CANTIDAD_MINIMA,
          3000
        );
        return;
      }

      const dialogRef = this.uiService.showModalCantidad({
        producto: this.listaProductoPedido[index],
        esEditarProducto: this.esEditarProducto,
        dialog: 'registrar_pedido',
      });
      dialogRef.afterClosed().subscribe((cantidad) => {
        if (this.listaProductoPedido[index].cantidad_producto == cantidad) {
          return;
        }
        if (this.listaProductoPedido[index].cantidad_producto == 1) {
          this.uiService.ShowSnackBar(
            SnackBarType.WARNING,
            Msjs.MSJ_PRODUCTO_CANTIDAD_MINIMA,
            3000
          );
          return;
        } else {
          const maximoADisminuir =
            this.listaProductoPedido[index].cantidad_producto - 1;
          const cantidaDisminuida =
            this.listaProductoPedido[index].cantidad_producto - cantidad;

          if (cantidaDisminuida > maximoADisminuir) {
            this.uiService.ShowSnackBar(
              SnackBarType.WARNING,
              Msjs.MSJ_PRODUCTO_CANTIDAD_MINIMA,
              3000
            );
            return;
          }
          this.listaProductoPedido[index].cantidad_producto_reducido =
            cantidaDisminuida;
          this.listaProductoPedido[index].es_modificado = true;
          if (this.esAdministrador) {
            this.listaProductoPedido[index].cantidad_producto =
              this.listaProductoPedido[index].cantidad_producto - 1;
            this.calcularTotalTicket();
          }
        }
      });
    } else {
      if (!this.esEditarProducto && !this.esEditar) {
        const dialogRef = this.uiService.showModalCantidad({
          producto: this.listaProductoPedido[index],
          esEditarProducto: this.esEditarProducto,
          dialog: 'registrar_pedido',
        });
        dialogRef.afterClosed().subscribe((cantidad) => {
          this.listaProductoPedido[index].cantidad_producto = cantidad;
          this.calcularTotalTicket();
        });
      } else {
        return;
      }
    }
  }

  minusProducto(producto: IProductoTicket) {
    if (producto.cantidad_producto == 1) {
      this.uiService.ShowSnackBar(
        SnackBarType.WARNING,
        Msjs.MSJ_PRODUCTO_CANTIDAD_MINIMA,
        3000
      );
      return;
    }
    if (!this.esAdministrador && producto.cantidad_producto_reducido) {
      const valorReducido = producto.cantidad_producto_reducido + 1;
      const maximoADisminuir = producto.cantidad_producto - 1;
      if (valorReducido > maximoADisminuir) {
        this.uiService.ShowSnackBar(
          SnackBarType.WARNING,
          Msjs.MSJ_PRODUCTO_CANTIDAD_MINIMA,
          3000
        );
        return;
      }
    }
    for (let i = 0; i < this.listaProductoPedido.length; i++) {
      if (this.listaProductoPedido[i].id == producto.id) {
        if (!this.esEditar && !this.esEditarProducto) {
          producto.cantidad_producto -= 1;
          //para agregar
          if (this.esAgregar) {
            this.calcularTotalTicket();
          }
          break;
        } else {
          const maximoADisminuir =
            this.listaProductoPedido[i].cantidad_producto - 1;
          this.listaProductoPedido[i].cantidad_producto_reducido =
            this.listaProductoPedido[i].cantidad_producto_reducido == undefined
              ? 0
              : this.listaProductoPedido[i].cantidad_producto_reducido;
          if (
            Number(this.listaProductoPedido[i].cantidad_producto_reducido) >
            maximoADisminuir
          ) {
            this.uiService.ShowSnackBar(
              SnackBarType.WARNING,
              Msjs.MSJ_PRODUCTO_CANTIDAD_MINIMA,
              3000
            );
            return;
          }
          const valor =
            Number(this.listaProductoPedido[i].cantidad_producto_reducido) + 1;
          this.listaProductoPedido[i].cantidad_producto_reducido = valor;
          this.listaProductoPedido[i].es_modificado = true;

          if (this.esAdministrador) {
            this.listaProductoPedido[i].cantidad_producto =
              this.listaProductoPedido[i].cantidad_producto - 1;
            this.calcularTotalTicket();
          }
          break;
        }
      }
    }
  }

  removeProducto(producto: IProductoTicket) {
    if (producto.incidencia != undefined) {
      this.uiService.ShowSnackBar(
        SnackBarType.INFO,
        Msjs.MSJ_PRODUCTO_INCIDENCIA,
        3000
      );
      return;
    }
    if (producto.descuento != undefined) {
      this.uiService.ShowSnackBar(
        SnackBarType.INFO,
        Msjs.MSJ_DSCTO_ERROR_EXISTE,
        3000
      );
      return;
    }

    if (producto.es_pagado != undefined) {
      this.uiService.ShowSnackBar(
        SnackBarType.INFO,
        Msjs.MSJ_PRODUCTO_PREPAGADO,
        3000
      );
      return;
    }

    if (this.esEditar && this.esEditarProducto) {
      const totalListaPedido = this.listaProductoPedido.filter(
        (prod) =>
          prod.es_eliminado != undefined || prod.es_modificado != undefined
      ).length;
      if (totalListaPedido == 1 && !this.esAdministrador) {
        this.uiService.ShowSnackBar(
          SnackBarType.INFO,
          Msjs.MSJ_PRODUCTO_CANTIDAD_MINIMA,
          3000
        );
        return;
      }
    }

    if (
      producto.estado_producto_vigente === EstadosProductoType.TERMINADO &&
      this.esEditar &&
      this.esEditarProducto
    ) {
      this.uiService.ShowSnackBar(
        SnackBarType.WARNING,
        Msjs.MSJ_PRODUCTO_TERMINADO,
        3000
      );
      return;
    }

    if (
      producto.estado_producto_vigente === EstadosProductoType.EN_PROCESO &&
      this.esEditar &&
      this.esEditarProducto
    ) {
      this.uiService.ShowSnackBar(
        SnackBarType.WARNING,
        Msjs.MSJ_PRODUCTO_EN_PROCESO,
        3000
      );
      return;
    }

    if (this.listaProductoPedido.length == 1 && this.esEditar) {
      this.uiService.ShowSnackBar(
        SnackBarType.WARNING,
        Msjs.MSJ_PRODUCTO_CANTIDAD_MINIMA,
        3000
      );
      return;
    }

    if (producto.es_modificado && this.esEditar && this.esEditarProducto) {
      this.uiService.ShowSnackBar(
        SnackBarType.WARNING,
        Msjs.MSJ_EDICION_PROCESO,
        3000
      );
      return;
    }
    //comprobando que minimanente se quede un producto que no sea prepagado en el pedido
    if (producto.es_pagado == undefined) {
      const tienePrepagado = this.listaProductoPedido.filter(
        (prod) => prod.es_pagado != undefined
      );
      if (
        this.listaProductoPedido.length <= 2 &&
        tienePrepagado &&
        tienePrepagado.length > 0
      ) {
        this.uiService.ShowSnackBar(
          SnackBarType.WARNING,
          Msjs.MSJ_PRODUCTO_CANTIDAD_MINIMA,
          3000
        );
        return;
      }
    }

    if (!this.esAdministrador && this.esEditar && this.esEditarProducto)
      for (let i = 0; i < this.listaProductoPedido.length; i++) {
        if (this.listaProductoPedido[i].id == producto.id) {
          this.listaProductoPedido[i].es_eliminado = true;
          break;
        }
      }
    else {
      const productoRemovido = JSON.parse(JSON.stringify(producto));
      productoRemovido.es_eliminado = true;
      this.listaProductoEliminado.push(productoRemovido);
      this.listaProductoPedido = [
        ...this.listaProductoPedido.filter(
          (prod) => prod.codigo_producto !== producto.codigo_producto
        ),
      ];
      this.calcularTotalTicket();
    }
  }

  calcularTotalTicket() {
    this.pedido.total = 0;
    this.pedido.sub_total = 0;
    this.pedido.igv = 0;

    for (const producto of (this.listaProductoPedido ??= [])) {
      producto.precio_total = 0;
      producto.precio_total =
        Number(producto.cantidad_producto) * Number(producto.precio_unitario);
      this.pedido.total += Number(producto.precio_total);
    }

    //conserva los montos anteriores
    if (this.esAgregar) {
      this.listaProductoGuardadoPrevio.forEach((prodPrev) => {
        //solo considera productos que no estan prepagados
        if (prodPrev.es_pagado == undefined) {
          this.pedido.total += prodPrev.precio_total;
        }
      });
    }

    //calculando el igv
    const montos = Utils.calcularTotalTicket(
      this.aspectosGenerales.incluir_servicio,
      this.aspectosGenerales.tasa_impuesto,
      this.pedido.total,
      Number(this.aspectosGenerales.tasa_servicio)
    );

    this.pedido.igv = montos[0]; //IGV,
    this.pedido.sub_total = montos[1]; //subTotal
    if (this.aspectosGenerales.incluir_servicio) {
      this.pedido.servicio_total = montos[2]; //tasaServicioCalculado
      this.pedido.servicio_subtotal=montos[3];//subtotal servicio
      this.pedido.servicio_igv=montos[4];
    }    
    this.pedido.total = montos[5]; // pedido redondeado
  }

  anularPedido(pedido: Pedido) {
    this.modalSb = this.uiService
      .showModalQuestions({
        title: Msjs.TITULO_CONFIRMACION,
        message: Msjs.MENSAJE_ANULACION_PEDIDO,
        icon: Icons.QUESTION_MARK,
      })
      .subscribe(async (result) => {
        if (result == true) {
          await this.cargarFechas();
          //generando historial de pedido
          let historialPedido: EstadoPedido | null = null;
          //generando historial de pedido
          historialPedido = new EstadoPedido();
          historialPedido.es_vigente = true;
          historialPedido.fecha_key = { ...this.fechaHoy };
          historialPedido.usuario_creacion = this.registroService.usuario.correo_electronico;
          historialPedido.auto_numerico = Utils.generaMesHoraAutonumerico(this.fechaHoy);
          historialPedido.estado_pedido = EstadosPedidoType.ANULADO;
          historialPedido.proceso = ProcesoType.ANULAR_PEDIDO;
          this.spinnerPageService.show();
          this.pedido = JSON.parse(JSON.stringify(pedido));
          this.pedido.estado_pedido_vigente = EstadosPedidoType.CERRADO;
          this.pedido.es_pedido_anulado = true; //indica que el pedido a sido anulado
          
          this.pedidoBLService.anularPedido(this.pedido, this.idTurno, historialPedido, this.fechaHoy)
            .then((tx) => {
              if (tx.tx) {
                this.uiService.ShowSnackBar(
                  SnackBarType.SUCCESS,
                  Msjs.MSJ_GUARDADO_OK,
                  5000
                );
                this.spinnerPageService.hide();
                //limpiando el pedido
                this.inicializarVariables(true, true);
                this.cancelarPedido(); //para limpiar las variables
              } else {
                this.uiService.ShowSnackBar(SnackBarType.ERROR, tx.error, 3000);
                this.spinnerPageService.hide();
              }
            });
        }
      });
  }

  onSubmit(f: NgForm) {
    if(this.esAdministrador && this.esPedidoIncidencia){
      if(this.incidenciaColaborador.id_usuario_cuenta==''){
        this.uiService.ShowSnackBar(SnackBarType.ERROR, Msjs.MSJ_ERROR_USUARIO_INCIDENCIA, 3000);
        return;
      }      
    }
    if ( f.valid && this.listaProductoPedido.length > 0 && this.pedido.codigo_pedido != '' ) {
      //solo para pedido incidencia
      let msjIncidencia:string='';
      if(this.esAdministrador && this.esPedidoIncidencia){
        msjIncidencia = Msjs.MENSAJE_CONFIRMACION_INCIDENCIA;
      }

      this.modalSb = this.uiService
        .showModalQuestions({
          title: Msjs.TITULO_CONFIRMACION,
          message: Msjs.MENSAJE_CONFIRMACION+msjIncidencia,
          icon: Icons.QUESTION_MARK,
        })
        .subscribe(async (result) => {
          if (result) {
            this.spinnerPageService.show();

            if (this.esNuevo) {
              this.operacion = SistOperation.NUEVO;
            } else if (this.esEditar) {
              this.operacion = SistOperation.EDITAR;
            } else if (this.esAgregar) {
              this.operacion = SistOperation.AGREGAR;
            }

            switch (this.operacion) {
              case SistOperation.NUEVO: {
                //comprobando que el turno este aperturado
                if (this.tieneTurnoAperturado) {
                  //**seteando estados resumen  de bebida y comida en pedido
                  let tieneBebida: boolean = false;
                  let tieneComida: Boolean = false;
                  this.listaProductoPedido.forEach((producto) => {
                    if ( producto.tipo_producto === ListaTipoProducto.listaTipoProducto[6].codigo_producto) {
                      producto.lista_caract_seleccionada?.forEach((caract) => {
                        if ( Utils.getTipoProducto(true).includes(caract.tipo_producto)) {
                          tieneBebida = true;
                        }
                        if (Utils.getTipoProducto(false).includes(caract.tipo_producto)) {
                          tieneComida = true;
                        }
                      });
                    } else {
                      if (!tieneBebida) {
                        const tieneBebidaSel = this.listaProductoPedido.filter(
                          (prod) =>
                            Utils.getTipoProducto(true).includes(
                              prod.tipo_producto
                            )
                        );
                        if (tieneBebida != undefined && tieneBebidaSel.length > 0
                        ) {
                          tieneBebida = true;
                        }
                      }
                      if (!tieneComida) {
                        const tieneComidaSel = this.listaProductoPedido.filter(
                          (prod) =>
                            Utils.getTipoProducto(false).includes(
                              prod.tipo_producto
                            )
                        );
                        if (
                          tieneComida != undefined &&
                          tieneComidaSel.length > 0
                        ) {
                          tieneComida = true;
                        }
                      }
                    }
                  });
                  if (tieneBebida) {
                    this.pedido.estado_pedido_vigente_bebida =
                      EstadosProductoType.PENDIENTE;
                  }
                  if (tieneComida) {
                    this.pedido.estado_pedido_vigente_comida =
                      EstadosProductoType.PENDIENTE;
                  }
                  //**

                  //cambiando estado a la mesa
                  this.pedido.ambiente_mesa.forEach((ambMesa) => {
                    ambMesa.mesa_estado = EstadosMesaType.OCUPADO;
                    this.pedido.mesas_completo.push(
                      ambMesa.mesa_numero.toString()
                    );
                  });

                  //generando historial de mesa
                  this.historialVariacionMesa = this.pedido.ambiente_mesa;

                  //generando historial de producto
                  this.listaProductoPedido.forEach((producto) => {
                    this.historialVariacionProducto.push({
                      fecha: this.fechaHoy,
                      usuario_creacion:
                        this.registroService.usuario.correo_electronico,
                      estado_producto: EstadosProductoType.PENDIENTE,
                      es_vigente: true,
                      auto_numerico: Utils.generaMesHoraAutonumerico(
                        this.fechaHoy
                      ),
                      codigo_ticket: producto.codigo_ticket,
                      cantidad_producto: producto.cantidad_producto,
                      nombre_producto: producto.nombre_producto,
                      codigo_producto: producto.codigo_producto,
                      reducir_cantidad: producto.reducir_cantidad,
                    });
                  });
                  //generando historial de pedido
                  this.historialVariacionPedido.es_vigente = true;
                  this.historialVariacionPedido.estado_pedido = EstadosPedidoType.PENDIENTE;
                  this.historialVariacionPedido.fecha_key = {
                    ...this.pedido.fecha_key,
                  };
                  this.historialVariacionPedido.usuario_creacion =
                    this.registroService.usuario.correo_electronico;
                  this.historialVariacionPedido.auto_numerico =
                    Utils.generaMesHoraAutonumerico(this.pedido.fecha_key);

                  //complementando pedido
                  this.pedido.es_vigente = true;
                  this.pedido.estado_pedido_vigente = EstadosPedidoType.PENDIENTE;
                  this.pedido.total_productos_pedidos = this.listaProductoPedido.length;
                  this.pedido.usuario_creacion_busqueda = this.registroService.usuario.correo_electronico.split('@')[0];
                  this.pedido.auto_numerico = Utils.generaMesHoraAutonumerico(this.pedido.fecha_key);

                  //flags de pago
                  this.pedido.tiene_pago_total = false;
                  this.pedido.tiene_pago_parcial = false;
                  
                  ////////////////////////////////////////////////
                  //1.CONSIDERANDO: PEDIDO NACE COMO INCIDENCIA
                  if(this.esAdministrador && this.esPedidoIncidencia){
                    this.pedido.es_pedido_incidencia=true//
                    const usuarioIncidencia:IUsuarioIncidencia={
                      apellido_materno:this.incidenciaColaborador.apellido_materno,
                      apellido_paterno:this.incidenciaColaborador.apellido_paterno,
                      correo_electronico:this.incidenciaColaborador.correo_electronico,
                      nombres:this.incidenciaColaborador.nombres
                    }
                    this.pedido.usuario_incidencia = usuarioIncidencia;
                    if(tieneBebida){
                      this.pedido.estado_pedido_vigente_bebida = EstadosProductoType.TERMINADO;
                    }
                    if (tieneComida) {
                      this.pedido.estado_pedido_vigente_comida = EstadosProductoType.TERMINADO;
                    }
                    //PEDIDO
                    this.pedido.estado_pedido_vigente = EstadosPedidoType.TERMINADO
                    //HISTORIAL ESTADO PEDIDO
                    this.historialVariacionPedido.estado_pedido = EstadosPedidoType.TERMINADO;

                    //PRODUCTO
                    this.listaProductoPedido.forEach(prod=>{
                      prod.estado_producto_vigente = EstadosProductoType.TERMINADO;
                      const tipoProdCombo = prod.tipo_producto;
                      //es bebida
                      if (Utils.getTipoProducto(true).includes(tipoProdCombo)) {
                        prod.estado_producto_vigente_bebida = EstadosProductoType.TERMINADO;
                      }
                      // es comida
                      if (Utils.getTipoProducto(false).includes(tipoProdCombo)) {
                        prod.estado_producto_vigente_comida = EstadosProductoType.TERMINADO;
                      }
                    });

                    //HISTORIAL
                    this.historialVariacionProducto.forEach(histProd=>{
                      histProd.estado_producto = EstadosProductoType.TERMINADO;
                    });
                  }



                  this.pedidoBLService
                    .insertPedido(
                      this.pedido,
                      this.idTurno,
                      this.listaProductoPedido,
                      this.historialVariacionPedido,
                      this.historialVariacionMesa,
                      this.historialVariacionProducto
                    )
                    .then((tx) => {
                      if (tx.tx) {
                        const msj = ' Pedido: ' + this.pedido.codigo_pedido;
                        this.uiService.ShowSnackBar(
                          SnackBarType.SUCCESS,
                          Msjs.MSJ_GUARDADO_OK + msj,
                          5000
                        );
                        //limpiando el pedido
                        this.inicializarVariables(false, true);
                        this.spinnerPageService.hide();
                      } else {
                        this.uiService.ShowSnackBar(
                          SnackBarType.ERROR,
                          tx.error,
                          3000
                        );
                        this.spinnerPageService.hide();
                      }
                    });
                } else {
                  this.spinnerPageService.hide();
                  this.uiService.ShowSnackBar(
                    SnackBarType.ERROR,
                    Msjs.MSJ_ERROR_NO_TURNO,
                    3000
                  );
                  return;
                }
                break;
              }
              case SistOperation.EDITAR: {
                await this.cargarFechas();

                if (this.esEditarMesa) {
                  //generando historial de pedido
                  this.historialVariacionPedido = new EstadoPedido();
                  this.historialVariacionPedido.es_vigente = true;
                  this.historialVariacionPedido.estado_pedido = !this.esAdministrador? EstadosPedidoType.PENDIENTE_REVISION: EstadosPedidoType.APROBADO;
                  this.historialVariacionPedido.auto_numerico = Utils.generaMesHoraAutonumerico(this.fechaHoy);
                  this.historialVariacionPedido.fecha_key = { ...this.fechaHoy,};
                  this.historialVariacionPedido.proceso = ProcesoType.CAMBIAR_VARIAR_MESA;

                  if (!this.esAdministrador) {
                    this.historialVariacionMesa = [];
                    //solo usuario de creacion
                    this.historialVariacionPedido.usuario_creacion =
                      this.registroService.usuario.correo_electronico;

                    //generando historial de mesa con copia profunda y limpia sin referenciar al objeto primordial
                    const variacionSolicitado:IAmbienteMesa[] = this.pedido.ambiente_mesa
                      .filter(
                        (item) =>
                          item.estado_cambio == SolicitarCambioType.SOLICITADO
                      )
                      .map((item) => JSON.parse(JSON.stringify(item)));
                    
                     // validando que se tengan cambios en mesas, para poder guardar
                      if(variacionSolicitado.length==0){
                        this.uiService.ShowSnackBar(
                          SnackBarType.WARNING,
                          Msjs.MSJ_SIN_CAMBIOS,
                          3000
                        );
                        this.spinnerPageService.hide();
                        return;
                      }
                    

                    variacionSolicitado.forEach((variacion) => {
                      variacion.fecha_key = { ...this.fechaHoy };
                      variacion.auto_numerico = Utils.generaMesHoraAutonumerico(this.fechaHoy);
                      this.historialVariacionMesa.push(variacion);
                    });

                    //completando el estado vigente del pedido
                    this.pedido.estado_pedido_vigente = EstadosPedidoType.PENDIENTE_REVISION;
                  } else {
                    // es administrador
                    // añadiendo el aprobado del historial de pedido
                    this.historialVariacionPedido.usuario_aprobacion = this.registroService.usuario.correo_electronico;

                    this.pedido.ambiente_mesa.forEach((ambMesa, index) => {
                      if (ambMesa.estado_cambio == SolicitarCambioType.SOLICITADO && ambMesa.accion_mesa == AccionMesaType.ANIADIR)
                        {
                          ambMesa.estado_cambio = SolicitarCambioType.APROBADO;
                          ambMesa.mesa_estado = EstadosMesaType.OCUPADO;
                          this.pedido.mesas_completo.push( ambMesa.mesa_numero.toString());
                          //generando fecha de historial
                          const historialMesa = JSON.parse(
                            JSON.stringify(ambMesa)
                          );
                          historialMesa.auto_numerico = Utils.generaMesHoraAutonumerico(this.fechaHoy);
                          historialMesa.fecha_key = { ...this.fechaHoy };
                          historialMesa.usuario_aprobacion = this.registroService.usuario.correo_electronico;
                          historialMesa.estado_cambio = SolicitarCambioType.APROBADO;
                          this.historialVariacionMesa.push(historialMesa);
                      } else {
                        if (
                          ambMesa.estado_cambio ==
                            SolicitarCambioType.APROBADO &&
                          ambMesa.accion_mesa == AccionMesaType.QUITAR
                        ) {
                          const mesasCompleto =
                            this.pedido.mesas_completo.filter(
                              (mesa) => mesa !== ambMesa.mesa_numero.toString()
                            );
                          this.pedido.mesas_completo = mesasCompleto;
                          //para administrador el historial es generado cuando remueve la mesa
                        }
                      }
                    });

                    //elimnando mesas del principal
                    const mesasFiltrado = this.pedido.ambiente_mesa.filter(
                      (obj1) =>
                        !this.historialVariacionMesa.some(
                          (obj2) =>
                            obj2.ambiente === obj1.ambiente &&
                            obj2.mesa_numero === obj1.mesa_numero &&
                            obj1.accion_mesa === AccionMesaType.QUITAR
                        )
                    );

                      // validando que se tengan cambios en mesas, para poder guardar
                      if(this.historialVariacionMesa.length==0){
                        this.uiService.ShowSnackBar(
                          SnackBarType.WARNING,
                          Msjs.MSJ_SIN_CAMBIOS,
                          3000
                        );
                        this.spinnerPageService.hide();
                        return;
                      }

                    this.pedido.ambiente_mesa = JSON.parse(
                      JSON.stringify(mesasFiltrado)
                    );
                  }
                }

                // solo se editan productos con estado pendiente
                if (this.esEditarProducto) {
                  //generando historial de pedido
                  this.historialVariacionPedido = new EstadoPedido();
                  this.historialVariacionPedido.es_vigente = true;
                  this.historialVariacionPedido.estado_pedido = !this.esAdministrador ? EstadosPedidoType.PENDIENTE_REVISION: EstadosPedidoType.APROBADO;
                  this.historialVariacionPedido.fecha_key = { ...this.fechaHoy};
                  this.historialVariacionPedido.proceso = ProcesoType.VARIAR_PRODUCTO;
                  this.historialVariacionPedido.auto_numerico = Utils.generaMesHoraAutonumerico(this.fechaHoy);
                  if (this.esAdministrador) {
                    this.historialVariacionPedido.usuario_aprobacion =
                      this.registroService.usuario.correo_electronico;
                  } else {
                    this.historialVariacionPedido.usuario_creacion = this.registroService.usuario.correo_electronico;
                  }

                  //generando historial de productos
                  const productosModificados: IProductoTicket[] =  this.listaProductoPedido.filter((prod) => prod.es_eliminado || prod.es_modificado).map((item) => JSON.parse(JSON.stringify(item)));

                  // validando que se tengan cambios en productos, para poder guardar
                  if(productosModificados.length==0){
                    this.uiService.ShowSnackBar(
                      SnackBarType.WARNING,
                      Msjs.MSJ_SIN_CAMBIOS,
                      3000
                    );
                    this.spinnerPageService.hide();
                    return;
                  }

                  productosModificados.forEach((producto) => {
                    const historialProd: EstadosProducto =
                      new EstadosProducto();
                    historialProd.fecha = { ...this.fechaHoy};
                    historialProd.usuario_creacion = this.registroService.usuario.correo_electronico;
                    historialProd.proceso = ProcesoType.VARIAR_PRODUCTO;
                    if (this.esAdministrador) {
                      historialProd.usuario_aprobacion = this.registroService.usuario.correo_electronico;
                      historialProd.estado_cambio = SolicitarCambioType.APROBADO;
                    } else {
                      historialProd.estado_cambio = SolicitarCambioType.SOLICITADO;
                      historialProd.usuario_creacion = this.registroService.usuario.correo_electronico;
                    }
                    historialProd.estado_producto = EstadosProductoType.PENDIENTE; //solo se reducen productos pendientes
                    historialProd.es_vigente = true;
                    historialProd.auto_numerico = Utils.generaMesHoraAutonumerico(this.fechaHoy);
                    historialProd.codigo_ticket = producto.codigo_ticket;
                    historialProd.codigo_producto = producto.codigo_producto;
                    historialProd.nombre_producto = producto.nombre_producto;
                    historialProd.cantidad_producto = producto.cantidad_producto;
                    historialProd.reducir_cantidad = producto.reducir_cantidad;
                    if (producto.es_modificado) {
                      historialProd.cantidad_producto_reducido = producto.cantidad_producto_reducido;
                      historialProd.es_modificado = true;
                    }
                    if (producto.es_eliminado) {
                      historialProd.es_eliminado = true;
                    }
                    this.historialVariacionProducto.push(historialProd);
                  });

                  //cuando es administrador los productos eliminados son guardados en otra lista distinta
                  if (this.esAdministrador) {
                    this.listaProductoEliminado.forEach((produ) => {
                      const historialProd: EstadosProducto = new EstadosProducto();
                      historialProd.fecha = { ...this.fechaHoy};
                      historialProd.usuario_creacion = this.registroService.usuario.correo_electronico;
                      historialProd.proceso = ProcesoType.VARIAR_PRODUCTO;
                      historialProd.usuario_aprobacion  = this.registroService.usuario.correo_electronico;
                      historialProd.estado_cambio = SolicitarCambioType.APROBADO;
                      historialProd.es_vigente = true;
                      historialProd.auto_numerico = Utils.generaMesHoraAutonumerico(this.fechaHoy);
                      historialProd.codigo_ticket = produ.codigo_ticket;
                      historialProd.cantidad_producto = produ.cantidad_producto;
                      historialProd.es_eliminado = true;
                      historialProd.nombre_producto = produ.nombre_producto;
                      historialProd.codigo_producto = produ.codigo_producto;
                      historialProd.reducir_cantidad = produ.reducir_cantidad;
                      this.historialVariacionProducto.push(historialProd);
                    });
                  }

                  //productos modificados
                  this.listaProductoPedido.forEach((producto) => {
                    if (producto.es_modificado) {
                      producto.estado_cambio = this.esAdministrador ? SolicitarCambioType.APROBADO : SolicitarCambioType.SOLICITADO;
                      producto.proceso = ProcesoType.VARIAR_PRODUCTO;
                    }
                  });
                  //filtrando los productos eliminados
                  if (!this.esAdministrador) {
                    this.listaProductoPedido.forEach((prod) => {
                      if (prod.es_eliminado) {
                        prod.estado_cambio = SolicitarCambioType.SOLICITADO;
                        prod.usuario_modificacion = this.registroService.usuario.correo_electronico;
                        prod.proceso = ProcesoType.VARIAR_PRODUCTO;
                      }
                    });
                  } else {
                    // para administrador, el metodo remover producto ya filtro los eliminados
                  }

                  //seteamos los productos
                  this.pedido.lista_producto = JSON.parse( JSON.stringify(this.listaProductoPedido));
                  this.pedido.total_productos_pedidos = this.pedido.lista_producto ? this.pedido.lista_producto.length: 0;

                  //generando estados de producto general a nivel de pedido
                  const estadosPermitidos = [
                    EstadosProductoType.PENDIENTE,
                    EstadosProductoType.EN_PROCESO,
                  ];
                  const tieneBebida = this.listaProductoPedido.filter(
                    (prod) =>
                      Utils.getTipoProducto(true).includes(
                        prod.tipo_producto
                      ) &&
                      estadosPermitidos.includes(prod.estado_producto_vigente)
                  );
                  const tieneComida = this.listaProductoPedido.filter(
                    (prod) =>
                      Utils.getTipoProducto(false).includes(
                        prod.tipo_producto
                      ) &&
                      estadosPermitidos.includes(prod.estado_producto_vigente)
                  );
                  if (tieneBebida != undefined && tieneBebida.length > 0) {
                    this.pedido.estado_pedido_vigente_bebida =
                      Utils.generarEstadoResumenProducto(tieneBebida);
                  }
                  if (tieneComida != undefined && tieneComida.length > 0) {
                    this.pedido.estado_pedido_vigente_comida =
                      Utils.generarEstadoResumenProducto(tieneComida);
                  }

                  //el estado del pedido se determina en el acceso a datos
                }
                this.pedidoBLService
                  .updatePedido(
                    this.pedido,
                    this.idTurno,
                    this.esEditarMesa,
                    this.esEditarProducto,
                    this.historialVariacionMesa,
                    this.historialVariacionProducto,
                    this.historialVariacionPedido,
                    this.listaProductoEliminado,
                    this.uiNavigateSide.goOptionSidenavItem //se envia para conocer el modulo de donde se generaré el mensaje de aprobación en caso de no ser administrador
                  )
                  .then((tx) => {
                    if (tx.tx) {
                      this.uiService.ShowSnackBar(
                        SnackBarType.SUCCESS,
                        Msjs.MSJ_GUARDADO_OK,
                        5000
                      );
                      //limpiando el pedido
                      this.inicializarVariables(true, true);
                      this.cancelarPedido(); //para limpiar las variables
                      this.spinnerPageService.hide();
                    } else {
                      this.uiService.ShowSnackBar(
                        SnackBarType.ERROR,
                        tx.error,
                        3000
                      );
                      this.spinnerPageService.hide();
                    }
                  });
                break;
              }
              case SistOperation.AGREGAR: {
                const tieneBebida = this.listaProductoPedido.filter((prod) =>
                  Utils.getTipoProducto(true).includes(prod.tipo_producto)
                );
                const tieneComida = this.listaProductoPedido.filter((prod) =>
                  Utils.getTipoProducto(false).includes(prod.tipo_producto)
                );
                if (tieneBebida != undefined && tieneBebida.length > 0) {
                  if (
                    this.pedido.estado_pedido_vigente_bebida == undefined ||
                    this.pedido.estado_pedido_vigente_bebida ==
                      EstadosProductoType.TERMINADO
                  ) {
                    this.pedido.estado_pedido_vigente_bebida =
                      EstadosProductoType.PENDIENTE;
                  }
                }
                if (tieneComida != undefined && tieneComida.length > 0) {
                  if (
                    this.pedido.estado_pedido_vigente_comida == undefined ||
                    this.pedido.estado_pedido_vigente_comida ==
                      EstadosProductoType.TERMINADO
                  ) {
                    this.pedido.estado_pedido_vigente_comida =
                      EstadosProductoType.PENDIENTE;
                  }
                }
                //

                //generando historial de pedido
                this.historialVariacionPedido.es_vigente = true;
                this.historialVariacionPedido.estado_pedido =
                  EstadosPedidoType.PENDIENTE;
                this.historialVariacionPedido.fecha_key = {
                  ...this.pedido.fecha_key,
                };
                this.historialVariacionPedido.usuario_creacion =
                  this.registroService.usuario.correo_electronico;
                this.historialVariacionPedido.auto_numerico =
                  Utils.generaMesHoraAutonumerico(this.pedido.fecha_key);

                //generando historial de producto
                this.listaProductoPedido.forEach((producto) => {
                  this.historialVariacionProducto.push({
                    fecha: this.fechaHoy,
                    usuario_creacion:
                      this.registroService.usuario.correo_electronico,
                    estado_producto: EstadosProductoType.PENDIENTE,
                    es_vigente: true,
                    auto_numerico: Utils.generaMesHoraAutonumerico(
                      this.fechaHoy
                    ),
                    codigo_ticket: producto.codigo_ticket,
                    cantidad_producto: producto.cantidad_producto,
                    nombre_producto: producto.nombre_producto,
                    codigo_producto: producto.codigo_producto,
                    reducir_cantidad: producto.reducir_cantidad,
                  });
                });

                //complementando pedido
                this.pedido.total_productos_pedidos =
                  this.listaProductoPedido.length +
                  this.listaProductoGuardadoPrevio.length;
                this.pedidoBLService
                  .updateAgregarProductoPedido(
                    this.pedido,
                    this.idTurno,
                    this.historialVariacionPedido,
                    this.historialVariacionProducto,
                    this.listaProductoPedido
                  )
                  .then((tx) => {
                    if (tx.tx) {
                      this.uiService.ShowSnackBar(
                        SnackBarType.SUCCESS,
                        Msjs.MSJ_GUARDADO_OK,
                        5000
                      );
                      this.spinnerPageService.hide();
                      this.inicializarVariables(true, true);
                      return;
                    } else {
                      this.uiService.ShowSnackBar(
                        SnackBarType.ERROR,
                        tx.error,
                        3000
                      );
                      this.spinnerPageService.hide();
                      return;
                    }
                  });
                break;
              }
              default: {
                break;
              }
            }
          }
        });
    } else {
      this.uiService.ShowSnackBar(
        SnackBarType.WARNING,
        Msjs.MSJ_SIN_PRODUCTO,
        3000
      );
    }
  }

  Limpiar() {
    this.cancelarPedido();
  }

  //#region editar
  btnEditarMesa() {
    //solo se permite una edicion a la vez o mesa o producto
    if (this.esEditarProducto) {
      this.uiService.ShowSnackBar(
        SnackBarType.INFO,
        Msjs.MSJ_EDICION_PROCESO,
        5000
      );
      return;
    }
    this.modalSb = this.uiService
      .showModalQuestions({
        title: Msjs.TITULO_CONFIRMACION,
        message: Msjs.MENSAJE_CONFIRMACION_MODIFICAR,
        icon: Icons.QUESTION_MARK,
      })
      .subscribe((result) => {
        if (result) {
          this.esEditarMesa = true;
          this.isDisabledMesa = false;
          //this.listarAmbienteMesaService.recargarMesas$.next();
        }
      });
  }

  //#region producto

  btnEditarProducto() {
    //solo se permite una edicion a la vez o mesa o producto
    if (this.esEditarMesa) {
      this.uiService.ShowSnackBar(
        SnackBarType.INFO,
        Msjs.MSJ_EDICION_PROCESO,
        5000
      );
      return;
    }
    this.modalSb = this.uiService
      .showModalQuestions({
        title: Msjs.TITULO_CONFIRMACION,
        message: Msjs.MENSAJE_CONFIRMACION_MODIFICAR,
        icon: Icons.QUESTION_MARK,
      })
      .subscribe((result) => {
        if (result) {
          this.isDisabledMesa = true;
          this.esEditarProducto = true;
          this.showCatalogo = false;
          this.esEditarMesa = false;
        }
      });
  }
  //#endregion
}
