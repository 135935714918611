<div style="margin-top: 20px;" fxLayout="column" fxLayoutGap="80px" >
    <section class="error-container">
        <span><span>4</span></span>
        <span>0</span>
        <span><span>4</span></span>
      </section>
    <div style="padding-top: 50px;" >      
        <div style="color: #616161; font-size: 18pt; text-align: center;" >Oops! No se encuentra la ruta</div>    
    </div>
</div>
