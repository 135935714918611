<div fxLayout="column" fxLayoutAlign="space-between stretch" style="height: 100vh;" >
    <mat-toolbar class="nav-principal"> 
        <div>
            <img class="nav-principal-logo-plataforma" src="../../../../assets/img/icon-modula.png" alt="Modula">
            <!-- <mat-icon class="icono-logo" >{{Icons.WEBHOOK}}</mat-icon> -->
        </div>
        <div class="nav-sidebar-logo-text" style="padding-top: 10px;">{{DatosPlataforma.NOMBRE_PLATAFORMA}}</div>        
    </mat-toolbar>
    <div style="padding-top: 50px; padding-bottom: 50px;" >
        <app-ruta404></app-ruta404>
    </div>    
    <div  style="color: #9E9E9E; font-size: 10pt; text-align: center;">{{texto}}</div>
    <app-footer></app-footer>
</div>

