import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ItemSidenav } from '../../models/item.model';
import { UIService } from '../../services/ui.services';
import {SistType } from '../../cons/common'
import { AuthService } from '../../auth/auth.service';
import { StaticRoutes } from '../../cons/route.class';
import { Router } from "@angular/router";
import { AutoUnsubscribe } from "../../helpers/decorators/AutoUnsubscribe";
import { DatosPlataforma } from '../../cons/common'
import { Icons } from '../../cons/icons';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
@AutoUnsubscribe

export class NavBarComponent implements OnInit {
  @Input() list_tab:ItemSidenav | any =[];
  @Input() use_btn_signup:boolean = false;
  @Input() use_btn_login:boolean = false;
  @Input() use_btn_login_mobile:boolean = false;
  @Input() inicialRoute!:ItemSidenav;
  loginState!: Subscription;
  constructor(
    private uiService:UIService,
    private authService:AuthService,
    private router: Router,
    private matIconRegistry: MatIconRegistry, 
    private domSanitizer: DomSanitizer,
    ) {
      this.matIconRegistry.addSvgIcon(
        'icon-wath', // Nombre del ícono para usar en el template
        this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/icons/whats-white.svg') 
      );
     }

  @Output() sidenavToggle = new EventEmitter<void>();
  selectedRouteSubs!: Subscription;
  es_autenticado:boolean=false;
  readonly DatosPlataforma: typeof DatosPlataforma = DatosPlataforma;
  readonly Icons: typeof Icons = Icons;

  ngOnInit(): void {
    this.selectedRouteSubs = this.uiService.selectedRoute.subscribe(item=>{
      this.list_tab.forEach((item_anterior:ItemSidenav)=>item_anterior.active=false);
      this.list_tab.filter((elemento:ItemSidenav)=>{
        elemento.active=elemento.nombre==item.nombre?true:false;
      })
    });

    this.loginState = this.authService.authChangeCodes$.subscribe(result=>{     
      if(result.uid && result.email_verified){
        this.es_autenticado=true;
      }
      else{
        this.es_autenticado = false;
      }
    });
  }

  onToggleSidenav(){
    this.sidenavToggle.emit();
  }

  selectRoute(item:ItemSidenav ){
    this.list_tab.forEach((elemento: ItemSidenav) => {
      elemento.active=false;
    });
    this.list_tab.filter( (elemento: ItemSidenav )=> elemento.active=elemento.nombre==item.nombre?true:false);
    this.uiService.selectedRoute.next(item);

  }

  openModalLogin(){
    this.uiService.showModalLogin({operacion:SistType.LOGIN});
  }

  openModalSignup(){
    //this.uiService.showModalSignup();
    this.uiService.ShowModalCaptcha();
  }



  onLogout(){
    this.authService.logout();
  }

  abrirWathsAppLink(){
    const msj = "Hola, quiero informes"
    const url = `https://wa.me/${'51'+this.DatosPlataforma.NUMERO_SOPORTE!}?text=${encodeURIComponent(msj)}`;

    window.open(url, '_blank');
  }

}
