import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { DatosPlataforma } from 'src/app/shared/cons/common';
import { StaticRoutes } from 'src/app/shared/cons/route.class';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  counter: number=20;
  texto:string=''
  intervalId: any;
 readonly DatosPlataforma: typeof DatosPlataforma = DatosPlataforma;
  constructor(
    private router: Router,
    private authService:AuthService,
  ) { }

  ngOnInit(): void {
    this.startTimerSMS();
  }

  startTimerSMS(){
    this.intervalId = setInterval(()=>{
      this.counter--;
      this.texto = `Serás redirigido en (${this.counter}) seg.`;    
      if(this.counter===0){
        this.authService.logout();
        clearInterval(this.intervalId);
        this.router.navigate(['/inicio/' + StaticRoutes.TOUR]);        
      }
    },1000);
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }


}
