<mat-toolbar class="nav-principal">
  <div fxHide.gt-xs>
    <button mat-icon-button type="button" (click)="onToggleSidenav()" >
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div >
    <img class="nav-principal-logo-plataforma" src="../../../../assets/img/icon-modula.png" alt="Modula">
    <!-- <mat-icon class="icono-logo" >{{Icons.WEBHOOK}}</mat-icon> -->
  </div>
  <div (click)="selectRoute(inicialRoute)" style="padding-top: 10px;" ><a class="nav-sidebar-logo-text" [routerLink]="inicialRoute.link">{{DatosPlataforma.NOMBRE_PLATAFORMA}}</a></div>
  <!-- <span class="nav-principal-slogan-plataforma" ></span> -->
  <div fxFlex fxLayout fxLayoutAlign="end center" fxHide.xs>
    <ul fxLayout="row" fxLayoutGap="20px"  class="navigation-items">
      <li *ngFor="let item of list_tab" [ngClass]="item.active ? 'navigation-items--active':''"><a [routerLink]="item.link" (click)="selectRoute(item)" >{{item.nombre}}</a></li>
    </ul>
  </div>
  <div style="margin-left: 10px;" fxFlex fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px" fxLayoutGap.xs="-5px">
    <button matTooltip="Contacto" mat-icon-button  (click)="abrirWathsAppLink()" aria-label="Contacto">
      <mat-icon svgIcon="icon-wath"></mat-icon>
    </button>
    <app-button *ngIf="use_btn_login && !es_autenticado" type="outline" text="Iniciar Sesión" fxHide.xs (click)="openModalLogin()"  ></app-button>
    <app-button *ngIf="es_autenticado" type="outline" text="Salir" fxHide.xs (click)="onLogout()"  ></app-button>
    <!-- desactivado temporalmente por estar en la web -->    
    <!-- <app-button *ngIf="use_btn_signup && !es_autenticado" type="normal" text="Registrarme" fxHide.xs (click)="openModalSignup()" ></app-button> -->
    <app-button *ngIf="!es_autenticado" type="fab_icon" icon_fig="login" fxHide fxShow.xs (click)="openModalLogin()"></app-button>
    <app-button *ngIf="es_autenticado" type="fab_icon" icon_fig="logout" fxHide fxShow.xs (click)="onLogout()"></app-button>
  </div>
  </mat-toolbar>
