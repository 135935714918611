<input type="file" class="file-input" (change)="onFilesSelected($event)" #fileUpload [multiple]="multipleFiles"
  [accept]="requiredFileType">
<div fxLayout="column" fxLayoutAlign="space-between center" fxLayout.xs="column" fxLayoutGap="5px">
  <div class="label">{{label}}</div>
  <div class="label-max">Tamaño máximo de archivo {{max_size_file/1000}}Kb.</div>
  <div *ngIf="max_size_width_height!=null" class="label-max">{{max_size_width_height}}</div>

  <div fxLayout="row" fxLayout.xs="column"  fxLayoutGap = "5px" fxLayoutGap.xs="8px" fxLayoutAlign.xs="end center" fxLayoutAlign="space-around center">
    <!-- boton -->
    <div *ngIf="(max_archivos>images_uploaded.length)&&
        (max_archivos>files_upload.length) &&
        (max_archivos>num_files) &&
        (!is_view)"
        >
      <button mat-mini-fab color="primary" class="upload-btn" (click)="fileUpload.click()" type="button" [disabled]="disabled">
        <mat-icon>attach_file</mat-icon>
      </button>
    </div>

    <div>
      <!-- imagen nueva -->
      <div *ngIf="files_upload.length>0" class="attached">
        <mat-chip-list>
          <mat-chip *ngFor="let file of files_upload" [selectable]="selectable" [removable]="removable"
            (removed)="removeFile(file)">{{file.name |
            sizeLabel: 8}}
            <button matChipRemove *ngIf="removable" [disabled]="disabled">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
        </mat-chip-list>

      </div>
      <!-- imagen editada -->
      <div *ngIf="is_edit || is_view">
        <mat-chip-list>
          <mat-chip *ngFor="let file of images_uploaded" [selectable]="selectable" [removable]="removable"
            (removed)="removeFileEdit(file)">{{file.nombre_original | sizeLabel: 8}}
            <button matChipRemove *ngIf="removable" [disabled]="disabled">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>
  </div>
</div>
